.layout-sidebar {
  position: fixed;
  width: 280px;
  height: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  user-select: none;
  left: 0;
  transition:
    transform $transitionDuration,
    left $transitionDuration;
  background-color: #2e404c;
  padding-left: 1.2rem;
  box-shadow:
    0px 3px 5px rgba(0, 0, 0, 0.02),
    0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.08);

  .layout-menu-container {
    overflow: auto;
    flex: 1;
    padding-bottom: 2rem;
    padding-right: 1rem;
  }
}

.layout-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;

  .layout-root-menuitem {
    > .layout-menuitem-root-text {
      font-size: 0.857rem;
      text-transform: uppercase;
      font-weight: 700;
      color: var(--surface-900);
      margin: 0.75rem 0;
    }

    > a {
      display: none;
    }
  }

  a {
    user-select: none;

    &.active-menuitem {
      > .layout-submenu-toggler {
        transform: rotate(-180deg);
      }
    }
  }

  li {
    margin: 4px 0;
  }

  li.active-menuitem {
    > a {
      .layout-submenu-toggler {
        transform: rotate(-180deg);
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    a {
      display: flex;
      align-items: center;
      position: relative;
      outline: 0 none;
      color: #ffffff;
      cursor: pointer;
      padding: 1rem 1rem;
      border-radius: 6px;
      transition:
        background-color $transitionDuration,
        box-shadow $transitionDuration;

      .layout-menuitem-icon {
        margin-right: 0.5rem;
      }

      .layout-submenu-toggler {
        font-size: 75%;
        margin-left: auto;
        transition: transform $transitionDuration;
      }

      &.active-route {
        font-weight: 700;
        color: #fff;
        background-color: var(--primary-500);
        border-radius: 6px;
        // color: var(--primary-color);

        &:hover {
          background-color: var(--primary-500);
        }
      }

      &:hover {
        background-color: var(--primary-transparent-50);
        border-radius: 6px;
      }

      &:focus {
        @include focused-inset();
      }
    }

    ul {
      overflow: hidden;
      // border-radius: $borderRadius;

      li {
        a {
          margin-left: 1rem;
        }

        li {
          a {
            margin-left: 2rem;
          }

          li {
            a {
              margin-left: 2.5rem;
            }

            li {
              a {
                margin-left: 3rem;
              }

              li {
                a {
                  margin-left: 3.5rem;
                }

                li {
                  a {
                    margin-left: 4rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
