@import 'assets/styles/layout/layout.scss';
@import 'assets/styles/layout/theme/theme.css';

@import 'assets/styles/themes/index.scss';

/* Bootstrap icons */
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';

/* PrimeNG */
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';

/* json viewer */
@import './assets/styles/components/ngx-json-viewer.theme.scss';
