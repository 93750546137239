:root {
  --ngx-json-string: #2d8b00;
  --ngx-json-number: #2d8b00;
  --ngx-json-boolean: #2d8b00;
  --ngx-json-date: #2d8b00;
  --ngx-json-array: #2d8b00;
  --ngx-json-object: #2d8b00;
  --ngx-json-null-bg: #505d73;
  --ngx-json-undefined: #2d8b00;
  --ngx-json-value: #2d8b00;
  --ngx-json-font-size: 16px;
  --ngx-json-font-family: Inter, Helvetica, 'sans-serif';
}
