:root {
  font-family: Roboto, Inter, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  --font-family: Roboto, Inter, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  --surface-a: #ffffff;
  --surface-b: #fafafa;
  --surface-c: #f4f4f5;
  --surface-d: #e5e7eb;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #374254;
  --text-color-secondary: #71717a;
  --primary-color: #f8b114;
  --primary-color-text: #ffffff;
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f4f4f5;
  --surface-200: #e4e4e7;
  --surface-300: #d4d4d8;
  --surface-400: #a1a1aa;
  --surface-500: #71717a;
  --surface-600: #52525b;
  --surface-700: #3f3f46;
  --surface-800: #27272a;
  --surface-900: #18181b;
  --gray-50: #f6f6f6;
  --gray-100: #e7e7e7;
  --gray-200: #d1d1d1;
  --gray-300: #b0b0b0;
  --gray-400: #888888;
  --gray-500: #6d6d6d;
  --gray-600: #5d5d5d;
  --gray-700: #4f4f4f;
  --gray-800: #454545;
  --gray-900: #3d3d3d;
  --content-padding: 1.25rem;
  --inline-spacing: 0.5rem;
  --border-radius: 0.375rem;
  --surface-ground: #fafafa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #e5e7eb;
  --surface-hover: #f4f4f5;
  --highlight-bg: #fdfaf1;
  --highlight-text-color: #ff8e42;
  --focus-ring: 0 0 0 1px #d3a78a;
  --maskbg: rgba(0, 0, 0, 0.4);
  color-scheme: light;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('./fonts/Inter-Light.woff2') format('woff2'),
    url('./fonts/Inter-Light.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('./fonts/Inter-Regular.woff2') format('woff2'),
    url('./fonts/Inter-Regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('./fonts/Inter-Medium.woff2') format('woff2'),
    url('./fonts/Inter-Medium.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('./fonts/Inter-SemiBold.woff2') format('woff2'),
    url('./fonts/Inter-SemiBold.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('./fonts/Inter-Bold.woff2') format('woff2'),
    url('./fonts/Inter-Bold.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ROBOTO */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('./fonts/Roboto-Light.ttf') format('truetype'),
    url('./fonts/Roboto-Light.ttf') format('truetype');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('./fonts/Roboto-Regular.ttf') format('truetype'),
    url('./fonts/Roboto-Regular.ttf') format('truetype');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('./fonts/Roboto-Medium.ttf') format('truetype'),
    url('./fonts/Roboto-Medium.ttf') format('truetype');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('./fonts/Roboto-Bold.ttf') format('truetype'),
    url('./fonts/Roboto-Bold.ttf') format('truetype');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local(''), url('./fonts/Roboto-Black.ttf') format('ttf'),
    url('./fonts/Roboto-Black.ttf') format('ttf');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

:root {
  --blue-50: #f5f9ff;
  --blue-100: #d0e1fd;
  --blue-200: #abc9fb;
  --blue-300: #85b2f9;
  --blue-400: #609af8;
  --blue-500: #3b82f6;
  --blue-600: #326fd1;
  --blue-700: #295bac;
  --blue-800: #204887;
  --blue-900: #183462;
  --green-50: #f4fcf7;
  --green-100: #caf1d8;
  --green-200: #a0e6ba;
  --green-300: #76db9b;
  --green-400: #4cd07d;
  --green-500: #22c55e;
  --green-600: #1da750;
  --green-700: #188a42;
  --green-800: #136c34;
  --green-900: #0e4f26;
  --yellow-50: #fefbf3;
  --yellow-100: #faedc4;
  --yellow-200: #f6de95;
  --yellow-300: #f2d066;
  --yellow-400: #eec137;
  --yellow-500: #eab308;
  --yellow-600: #c79807;
  --yellow-700: #a47d06;
  --yellow-800: #816204;
  --yellow-900: #5e4803;
  --cyan-50: #f3fbfd;
  --cyan-100: #c3edf5;
  --cyan-200: #94e0ed;
  --cyan-300: #65d2e4;
  --cyan-400: #35c4dc;
  --cyan-500: #06b6d4;
  --cyan-600: #059bb4;
  --cyan-700: #047f94;
  --cyan-800: #036475;
  --cyan-900: #024955;
  --pink-50: #fef6fa;
  --pink-100: #fad3e7;
  --pink-200: #f7b0d3;
  --pink-300: #f38ec0;
  --pink-400: #f06bac;
  --pink-500: #ec4899;
  --pink-600: #c93d82;
  --pink-700: #a5326b;
  --pink-800: #822854;
  --pink-900: #5e1d3d;
  --indigo-50: #f7f7fe;
  --indigo-100: #dadafc;
  --indigo-200: #bcbdf9;
  --indigo-300: #9ea0f6;
  --indigo-400: #8183f4;
  --indigo-500: #d3a78a;
  --indigo-600: #5457cd;
  --indigo-700: #4547a9;
  --indigo-800: #363885;
  --indigo-900: #282960;
  --teal-50: #f3fbfb;
  --teal-100: #c7eeea;
  --teal-200: #9ae0d9;
  --teal-300: #6dd3c8;
  --teal-400: #41c5b7;
  --teal-500: #14b8a6;
  --teal-600: #119c8d;
  --teal-700: #0e8174;
  --teal-800: #0b655b;
  --teal-900: #084a42;
  --orange-50: #fef6f6;
  --orange-100: #fddcc3;
  --orange-200: #fdc19b;
  --orange-300: #faa96f;
  --orange-400: #f98e43;
  --orange-500: #f8b114;
  --orange-600: #c98a0f;
  --orange-700: #a16f0c;
  --orange-800: #7a5409;
  --orange-900: #523906;
  --bluegray-50: #f7f8f9;
  --bluegray-100: #dadee3;
  --bluegray-200: #bcc3cd;
  --bluegray-300: #9fa9b7;
  --bluegray-400: #818ea1;
  --bluegray-500: #64748b;
  --bluegray-600: #556376;
  --bluegray-700: #465161;
  --bluegray-800: #37404c;
  --bluegray-900: #282e38;
  --purple-50: #fbf7ff;
  --purple-100: #ead6fd;
  --purple-200: #dab6fc;
  --purple-300: #c996fa;
  --purple-400: #b975f9;
  --purple-500: #a855f7;
  --purple-600: #8f48d2;
  --purple-700: #763cad;
  --purple-800: #5c2f88;
  --purple-900: #432263;
  --red-50: #fef6f6;
  --red-100: #fbd2d2;
  --red-200: #f8afaf;
  --red-300: #f58b8b;
  --red-400: #f26868;
  --red-500: #ef4444;
  --red-600: #cb3a3a;
  --red-700: #a73030;
  --red-800: #832525;
  --red-900: #601b1b;
  --primary-50: #fffceb;
  --primary-100: #fff6c6;
  --primary-200: #feee89;
  --primary-300: #fedf4b;
  --primary-400: #fdcd22;
  --primary-500: #f8b114;
  --primary-600: #db8404;
  --primary-700: #b65e07;
  --primary-800: #93480d;
  --primary-900: #793b0e;
  --primary-transparent-50: #f8b0148a;
}

.p-editor-container .p-editor-toolbar {
  background: #fafafa;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #e5e7eb;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #71717a;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #71717a;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #71717a;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker
  .ql-picker-label:hover {
  color: #3f3f46;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker
  .ql-picker-label:hover
  .ql-stroke {
  stroke: #3f3f46;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker
  .ql-picker-label:hover
  .ql-fill {
  fill: #3f3f46;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-label {
  color: #3f3f46;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-label
  .ql-stroke {
  stroke: #3f3f46;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-label
  .ql-fill {
  fill: #3f3f46;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  padding: 0.25rem 0;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options
  .ql-picker-item {
  color: #3f3f46;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options
  .ql-picker-item:hover {
  color: #18181b;
  background: #f4f4f5;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded:not(.ql-icon-picker)
  .ql-picker-item {
  padding: 0.75rem 1rem;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #e5e7eb;
}
.p-editor-container .p-editor-content .ql-editor {
  background: #ffffff;
  color: #3f3f46;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #3f3f46;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #3f3f46;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #3f3f46;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: var(--primary-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: var(--primary-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: var(--primary-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container
  .ql-snow.ql-toolbar
  .ql-picker-label.ql-active
  .ql-picker-label,
.p-editor-container
  .ql-snow.ql-toolbar
  .ql-picker-item.ql-selected
  .ql-picker-label {
  color: var(--primary-color);
}

.p-progress-spinner-circle {
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite,
    p-progress-spinner-dash normal;
  stroke: currentColor;
}

.p-button-label {
  flex: none;
}

/* Begin My custom styles */
.grid > [class*='col'] .field {
  margin-bottom: 0.5rem;
}

.p-skeleton.bg-white:after {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(227, 227, 227, 0.503),
    rgba(255, 255, 255, 0)
  );
}

@media (min-width: 576px) {
  .p-paginator-prev {
    order: 2;
    margin-left: auto;
  }

  .p-paginator-next {
    order: 0;
  }

  .p-paginator-pages {
    order: 1;
  }

  p-table p-paginator p-dropdown {
    order: 3;
  }
}

.p-datatable .p-datatable-loading-overlay {
  border-radius: 1rem;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  color: var(--gray-800);
}

::-webkit-scrollbar {
  width: 7px; /* Ancho de la barra de desplazamiento */
  height: 7px; /* Alto de la barra de desplazamiento */
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #ffffff; /* Color de fondo de la pista */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--gray-200); /* Color de fondo del control deslizante */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(
    --gray-300
  ); /* Color de fondo del control deslizante al pasar el mouse */
}

.break-word {
  word-wrap: break-word;
}


/* End My custom styles */

@layer primeng {
  * {
    box-sizing: border-box;
  }

  .p-component {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    font-weight: normal;
  }

  .p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    transition-duration: 0.2s;
  }

  .p-disabled,
  .p-component:disabled {
    opacity: 0.6;
  }

  .p-error {
    color: #e24c4c;
  }

  .p-text-secondary {
    color: #71717a;
  }

  .pi {
    font-size: 1rem;
  }

  .p-icon {
    width: 1rem;
    height: 1rem;
  }

  .p-link {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    border-radius: 0.375rem;
  }
  .p-link:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }

  .p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards;
  }

  .p-component-overlay-leave {
    animation: p-component-overlay-leave-animation 150ms forwards;
  }

  @keyframes p-component-overlay-enter-animation {
    from {
      background-color: transparent;
    }
    to {
      background-color: var(--maskbg);
    }
  }
  @keyframes p-component-overlay-leave-animation {
    from {
      background-color: var(--maskbg);
    }
    to {
      background-color: transparent;
    }
  }

  .p-autocomplete .p-autocomplete-loader {
    right: 0.75rem;
  }
  .p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 3.75rem;
  }
  .p-autocomplete:not(.p-disabled):hover .p-autocomplete-multiple-container {
    border-color: #d4d4d8;
  }
  .p-autocomplete:not(.p-disabled).p-focus .p-autocomplete-multiple-container {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
    border-color: var(--primary-color);
  }
  .p-autocomplete .p-autocomplete-multiple-container {
    padding: 0.375rem 0.75rem;
    gap: 0.5rem;
  }
  .p-autocomplete
    .p-autocomplete-multiple-container
    .p-autocomplete-input-token {
    padding: 0.375rem 0;
  }
  .p-autocomplete
    .p-autocomplete-multiple-container
    .p-autocomplete-input-token
    input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    color: #3f3f46;
    padding: 0;
    margin: 0;
  }
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: 0.375rem 0.75rem;
    background: #e5e7eb;
    color: #3f3f46;
    border-radius: 16px;
  }
  .p-autocomplete
    .p-autocomplete-multiple-container
    .p-autocomplete-token
    .p-autocomplete-token-icon {
    margin-left: 0.5rem;
  }
  .p-autocomplete
    .p-autocomplete-multiple-container
    .p-autocomplete-token.p-focus {
    background: #d4d4d8;
    color: #3f3f46;
  }
  .p-autocomplete.p-invalid.p-component > .p-inputtext {
    border-color: #f0a9a7;
  }

  .p-autocomplete-panel {
    background: #ffffff;
    color: #3f3f46;
    border: 0 none;
    border-radius: 0.375rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .p-autocomplete-panel .p-autocomplete-items {
    padding: 0.25rem 0;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    margin: 0;
    padding: 0.75rem 1rem;
    border: 0 none;
    color: #3f3f46;
    background: transparent;
    transition: none;
    border-radius: 0;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    color: #312e81;
    background: var(--primary-100);
  }
  .p-autocomplete-panel
    .p-autocomplete-items
    .p-autocomplete-item.p-highlight.p-focus {
    /* background: #e0e7ff; */
  }
  .p-autocomplete-panel
    .p-autocomplete-items
    .p-autocomplete-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #18181b;
    background: #e5e7eb;
  }
  .p-autocomplete-panel
    .p-autocomplete-items
    .p-autocomplete-item:not(.p-highlight):not(.p-disabled):hover {
    color: #18181b;
    background: #f4f4f5;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #3f3f46;
    background: #ffffff;
    font-weight: 600;
  }

  p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
    border-color: #f0a9a7;
  }

  p-autocomplete.p-autocomplete-clearable .p-inputtext {
    padding-right: 2.5rem;
  }
  p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
    color: #71717a;
    right: 0.75rem;
  }

  p-autocomplete.p-autocomplete-clearable
    .p-autocomplete-dd
    .p-autocomplete-clear-icon {
    color: #71717a;
    right: 3.75rem;
  }

  p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
    border-color: #f0a9a7;
  }

  .p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
    border-color: var(--primary-color);
  }

  .p-datepicker {
    padding: 0.5rem;
    background: #ffffff;
    color: #3f3f46;
    border: 1px solid #d4d4d8;
    border-radius: 0.375rem;
  }
  .p-datepicker:not(.p-datepicker-inline) {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #ffffff;
  }
  .p-datepicker .p-datepicker-header {
    padding: 0.5rem;
    color: #3f3f46;
    background: #ffffff;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #e5e7eb;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev,
  .p-datepicker .p-datepicker-header .p-datepicker-next {
    width: 2rem;
    height: 2rem;
    color: #71717a;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: none;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
  .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: #18181b;
    border-color: transparent;
    background: #f4f4f5;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev:focus-visible,
  .p-datepicker .p-datepicker-header .p-datepicker-next:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 2rem;
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: #3f3f46;
    transition: none;
    font-weight: 600;
    padding: 0.5rem;
  }
  .p-datepicker
    .p-datepicker-header
    .p-datepicker-title
    .p-datepicker-year:enabled:hover,
  .p-datepicker
    .p-datepicker-header
    .p-datepicker-title
    .p-datepicker-month:enabled:hover {
    color: var(--primary-color);
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: 0.5rem;
  }
  .p-datepicker table {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
  .p-datepicker table th {
    padding: 0.5rem;
  }
  .p-datepicker table th > span {
    width: 2.5rem;
    height: 2.5rem;
  }
  .p-datepicker table td {
    padding: 0.5rem;
  }
  .p-datepicker table td > span {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: none;
    border: 1px solid transparent;
  }
  .p-datepicker table td > span.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }
  .p-datepicker table td > span:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-datepicker table td.p-datepicker-today > span {
    background: #d4d4d8;
    color: #3f3f46;
    border-color: transparent;
  }
  .p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }
  .p-datepicker .p-datepicker-buttonbar {
    padding: 1rem 0;
    border-top: 1px solid #f3f4f6;
  }
  .p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto;
  }
  .p-datepicker .p-timepicker {
    border-top: 1px solid #f3f4f6;
    padding: 0.5rem;
  }
  .p-datepicker .p-timepicker button {
    width: 2rem;
    height: 2rem;
    color: #71717a;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: none;
  }

  .p-datepicker .p-timepicker button:enabled:hover {
    color: #18181b;
    border-color: transparent;
    background: #f4f4f5;
  }
  .p-datepicker .p-timepicker button:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-datepicker .p-timepicker button:last-child {
    margin-top: 0.2em;
  }
  .p-datepicker .p-timepicker span {
    font-size: 1.25rem;
  }
  .p-datepicker .p-timepicker > div {
    padding: 0 0.5rem;
  }
  .p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none;
  }
  .p-datepicker .p-monthpicker {
    margin: 0.5rem 0;
  }
  .p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: 0.5rem;
    transition: none;
    border-radius: 0.375rem;
  }
  .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }
  .p-datepicker .p-yearpicker {
    margin: 0.5rem 0;
  }
  .p-datepicker .p-yearpicker .p-yearpicker-year {
    padding: 0.5rem;
    transition: none;
    border-radius: 0.375rem;
  }
  .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-left: 1px solid #f3f4f6;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    padding-left: 0;
    border-left: 0 none;
  }
  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    padding-right: 0;
  }
  .p-datepicker:not(.p-disabled)
    table
    td
    span:not(.p-highlight):not(.p-disabled):hover {
    background: #f4f4f5;
  }
  .p-datepicker:not(.p-disabled)
    table
    td
    span:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-datepicker:not(.p-disabled)
    .p-monthpicker
    .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
    background: #f4f4f5;
  }
  .p-datepicker:not(.p-disabled)
    .p-monthpicker
    .p-monthpicker-month:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-datepicker:not(.p-disabled)
    .p-yearpicker
    .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
    background: #f4f4f5;
  }
  .p-datepicker:not(.p-disabled)
    .p-yearpicker
    .p-yearpicker-year:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }

  p-calendar.p-calendar-clearable .p-inputtext {
    padding-right: 2.5rem;
  }
  p-calendar.p-calendar-clearable .p-calendar-clear-icon {
    color: #71717a;
    right: 0.75rem;
  }

  p-calendar.p-calendar-clearable .p-calendar-w-btn .p-calendar-clear-icon {
    color: #71717a;
    right: 3.75rem;
  }

  .p-element.p-ripple.p-datepicker-trigger:disabled {
    background: #c9c8c8;
    border: 1px solid #c9c8c8;
  }

  @media screen and (max-width: 769px) {
    .p-datepicker table th,
    .p-datepicker table td {
      padding: 0;
    }
  }
  .p-cascadeselect {
    background: #ffffff;
    border: 1px solid #d4d4d8;
    transition: none;
    border-radius: 0.375rem;
  }
  .p-cascadeselect:not(.p-disabled):hover {
    border-color: #d4d4d8;
  }
  .p-cascadeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
    border-color: var(--primary-color);
  }
  .p-cascadeselect .p-cascadeselect-label {
    background: transparent;
    border: 0 none;
    padding: 0.75rem 0.75rem;
  }
  .p-cascadeselect .p-cascadeselect-label.p-placeholder {
    color: #71717a;
  }
  .p-cascadeselect .p-cascadeselect-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
  }
  .p-cascadeselect .p-cascadeselect-trigger {
    background: transparent;
    color: #71717a;
    width: 3rem;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .p-cascadeselect.p-invalid.p-component {
    border-color: #f0a9a7;
  }

  .p-cascadeselect-panel {
    background: #ffffff;
    color: #3f3f46;
    border: 0 none;
    border-radius: 0.375rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .p-cascadeselect-panel .p-cascadeselect-items {
    padding: 0.25rem 0;
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
    margin: 0;
    border: 0 none;
    color: #3f3f46;
    background: transparent;
    transition: none;
    border-radius: 0;
  }
  .p-cascadeselect-panel
    .p-cascadeselect-items
    .p-cascadeselect-item.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }
  .p-cascadeselect-panel
    .p-cascadeselect-items
    .p-cascadeselect-item.p-highlight.p-focus {
    background: #e0e7ff;
  }
  .p-cascadeselect-panel
    .p-cascadeselect-items
    .p-cascadeselect-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #18181b;
    background: #e5e7eb;
  }
  .p-cascadeselect-panel
    .p-cascadeselect-items
    .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #18181b;
    background: #f4f4f5;
  }
  .p-cascadeselect-panel
    .p-cascadeselect-items
    .p-cascadeselect-item
    .p-cascadeselect-item-content {
    padding: 0.75rem 1rem;
  }
  .p-cascadeselect-panel
    .p-cascadeselect-items
    .p-cascadeselect-item
    .p-cascadeselect-group-icon {
    font-size: 0.875rem;
  }

  .p-input-filled .p-cascadeselect {
    background: #fafafa;
  }
  .p-input-filled .p-cascadeselect:not(.p-disabled):hover {
    background-color: #fafafa;
  }
  .p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
    background-color: #ffffff;
  }

  p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
    border-color: #f0a9a7;
  }

  p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label {
    padding-right: 0.75rem;
  }
  p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon {
    color: #71717a;
    right: 3rem;
  }

  .p-overlay-modal .p-cascadeselect-sublist .p-cascadeselect-panel {
    box-shadow: none;
    border-radius: 0;
    padding: 0.25rem 0 0.25rem 0.5rem;
  }
  .p-overlay-modal
    .p-cascadeselect-item-active
    > .p-cascadeselect-item-content
    .p-cascadeselect-group-icon {
    transform: rotate(90deg);
  }

  .p-checkbox {
    width: 16px;
    height: 16px;
  }
  .p-checkbox .p-checkbox-box {
    border: 2px solid #d4d4d8;
    background: #ffffff;
    width: 16px;
    height: 16px;
    color: #3f3f46;
    border-radius: 0.375rem;
    transition: none;
  }
  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: #ffffff;
    font-size: 10px;
  }
  .p-checkbox .p-checkbox-box .p-icon {
    width: 10px;
    height: 10px;
  }
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--primary-color);
    background: var(--primary-color);
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #d4d4d8;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
    border-color: var(--primary-color);
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--primary-color);
    background: var(--primary-color);
    color: #ffffff;
  }

  p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
    border-color: #f0a9a7;
  }

  .p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #fafafa;
  }
  .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: var(--primary-color);
  }
  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #fafafa;
  }
  .p-input-filled
    .p-checkbox:not(.p-checkbox-disabled)
    .p-checkbox-box.p-highlight:hover {
    background: var(--primary-color);
  }

  .p-checkbox-label {
    margin-left: 0.5rem;
  }

  p-tristatecheckbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
    border-color: #f0a9a7;
  }

  .p-chips:not(.p-disabled):hover .p-chips-multiple-container {
    border-color: #d4d4d8;
  }
  .p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
    border-color: var(--primary-color);
  }
  .p-chips .p-chips-multiple-container {
    padding: 0.375rem 0.75rem;
    gap: 0.5rem;
  }
  .p-chips .p-chips-multiple-container .p-chips-token {
    padding: 0.375rem 0.75rem;
    margin-right: 0.5rem;
    background: #e5e7eb;
    color: #3f3f46;
    border-radius: 16px;
  }
  .p-chips .p-chips-multiple-container .p-chips-token.p-focus {
    background: #d4d4d8;
    color: #3f3f46;
  }
  .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: 0.5rem;
  }
  .p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: 0.375rem 0;
  }
  .p-chips .p-chips-multiple-container .p-chips-input-token input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    color: #3f3f46;
    padding: 0;
    margin: 0;
  }

  p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
    border-color: #f0a9a7;
  }

  p-chips.p-chips-clearable .p-inputtext {
    padding-right: 1.75rem;
  }
  p-chips.p-chips-clearable .p-chips-clear-icon {
    color: #71717a;
    right: 0.75rem;
  }

  .p-colorpicker-preview,
  .p-fluid .p-colorpicker-preview.p-inputtext {
    width: 2rem;
    height: 2rem;
  }

  .p-colorpicker-panel {
    background: #27272a;
    border: 1px solid #18181b;
  }
  .p-colorpicker-panel .p-colorpicker-color-handle,
  .p-colorpicker-panel .p-colorpicker-hue-handle {
    border-color: #ffffff;
  }

  .p-colorpicker-overlay-panel {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  .p-dropdown {
    background: #ffffff;
    border: 1px solid #d4d4d8;
    transition: none;
    border-radius: 0.375rem;
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: #d4d4d8;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
    border-color: var(--primary-color);
  }
  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.75rem;
  }
  .p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
  }
  .p-dropdown .p-dropdown-label.p-placeholder {
    color: var(--gray-200);
  }
  .p-dropdown .p-dropdown-label:focus,
  .p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
  }
  .p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: #71717a;
    width: 3rem;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .p-dropdown .p-dropdown-clear-icon {
    color: #71717a;
    right: 3rem;
  }
  .p-dropdown.p-invalid.p-component {
    border-color: #f0a9a7;
  }

  .p-dropdown-panel {
    background: #ffffff;
    color: #3f3f46;
    border: 0 none;
    border-radius: 0.375rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .p-dropdown-panel .p-dropdown-header {
    padding: 0.5rem 0.75rem;
    border-bottom: 0 none;
    color: #3f3f46;
    background: #fafafa;
    margin: 0;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.75rem;
    margin-right: -1.75rem;
  }
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.75rem;
    color: #71717a;
  }
  .p-dropdown-panel .p-dropdown-items {
    padding: 0.25rem 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.75rem 1rem;
    border: 0 none;
    color: #3f3f46;
    background: transparent;
    transition: none;
    border-radius: 0;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
    background: var(--primary-100);
  }
  .p-dropdown-panel
    .p-dropdown-items
    .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #18181b;
    background: #e5e7eb;
  }
  .p-dropdown-panel
    .p-dropdown-items
    .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #18181b;
    background: #f4f4f5;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #3f3f46;
    background: #ffffff;
    font-weight: 600;
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: 0.75rem 1rem;
    color: #3f3f46;
    background: transparent;
  }

  .p-input-filled .p-dropdown {
    background: #fafafa;
  }
  .p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: #fafafa;
  }
  .p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    background-color: #ffffff;
  }
  .p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
    background-color: transparent;
  }

  p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
    border-color: #f0a9a7;
  }

  .p-inputgroup-addon {
    background: #fafafa;
    color: #71717a;
    border-top: 1px solid #d4d4d8;
    border-left: 1px solid #d4d4d8;
    border-bottom: 1px solid #d4d4d8;
    padding: 0.75rem 0.75rem;
    min-width: 3rem;
  }
  .p-inputgroup-addon:last-child {
    border-right: 1px solid #d4d4d8;
  }

  .p-inputgroup > .p-component,
  .p-inputgroup > .p-inputwrapper > .p-inputtext,
  .p-inputgroup > .p-float-label > .p-component {
    border-radius: 0;
    margin: 0;
  }
  .p-inputgroup > .p-component + .p-inputgroup-addon,
  .p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
  .p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0 none;
  }
  .p-inputgroup > .p-component:focus,
  .p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
  .p-inputgroup > .p-float-label > .p-component:focus {
    z-index: 1;
  }
  .p-inputgroup > .p-component:focus ~ label,
  .p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
  .p-inputgroup > .p-float-label > .p-component:focus ~ label {
    z-index: 1;
  }

  .p-inputgroup-addon:first-child,
  .p-inputgroup button:first-child,
  .p-inputgroup input:first-child,
  .p-inputgroup > .p-inputwrapper:first-child > .p-component,
  .p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .p-inputgroup-addon:last-child,
  .p-inputgroup button:last-child,
  .p-inputgroup input:last-child,
  .p-inputgroup > .p-inputwrapper:last-child > .p-component,
  .p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .p-fluid .p-inputgroup .p-button {
    width: auto;
  }
  .p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 3rem;
  }

  p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
    border-color: #f0a9a7;
  }

  p-inputmask.p-inputmask-clearable .p-inputtext {
    padding-right: 2.5rem;
  }
  p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon {
    color: #71717a;
    right: 0.75rem;
  }

  p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
    border-color: #f0a9a7;
  }

  p-inputnumber.p-inputnumber-clearable .p-inputnumber-input {
    padding-right: 2.5rem;
  }
  p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
    color: #71717a;
    right: 0.75rem;
  }

  p-inputnumber.p-inputnumber-clearable
    .p-inputnumber-buttons-stacked
    .p-inputnumber-clear-icon {
    right: 3.75rem;
  }
  p-inputnumber.p-inputnumber-clearable
    .p-inputnumber-buttons-horizontal
    .p-inputnumber-clear-icon {
    right: 3.75rem;
  }

  .p-inputswitch {
    width: 3rem;
    height: 1.75rem;
  }
  .p-inputswitch .p-inputswitch-slider {
    background: #d4d4d8;
    transition: none;
    border-radius: 30px;
  }
  .p-inputswitch .p-inputswitch-slider:before {
    background: #ffffff;
    width: 1.25rem;
    height: 1.25rem;
    left: 0.25rem;
    margin-top: -0.625rem;
    border-radius: 50%;
    transition-duration: 0.2s;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.25rem);
  }
  .p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #bebec4;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: var(--primary-color);
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #ffffff;
  }
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
    .p-inputswitch-slider {
    background: var(--primary-600);
  }

  p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch > .p-inputswitch-slider {
    border-color: #f0a9a7;
  }

  .p-inputtext {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    color: #3f3f46;
    background: #ffffff;
    padding: 0.75rem 0.6rem;
    border: 1px solid #d4d4d8;
    transition: none;
    appearance: none;
    border-radius: 0.375rem;
  }
  .p-inputtext:enabled:hover {
    border-color: #d4d4d8;
  }
  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
    border-color: var(--primary-color);
  }
  .p-inputtext.ng-dirty.ng-invalid {
    border-color: #f0a9a7;
  }
  .p-inputtext.p-inputtext-sm {
    font-size: 0.875rem;
    padding: 0.65625rem 0.65625rem;
  }
  .p-inputtext.p-inputtext-lg {
    font-size: 1.25rem;
    padding: 0.9375rem 0.9375rem;
  }

  .p-float-label > label {
    left: 0.75rem;
    color: #71717a;
    transition-duration: 0.2s;
  }

  .p-float-label > .ng-invalid.ng-dirty + label {
    color: #f0a9a7;
  }

  .p-input-icon-left > .p-icon-wrapper.p-icon,
  .p-input-icon-left > i:first-of-type {
    left: 0.75rem;
    color: #71717a;
  }

  .p-input-icon-left > .p-inputtext {
    padding-left: 2.5rem;
  }

  .p-input-icon-left.p-float-label > label {
    left: 2.5rem;
  }

  .p-input-icon-right > .p-icon-wrapper,
  .p-input-icon-right > i:last-of-type {
    right: 0.75rem;
    color: #71717a;
  }

  .p-input-icon-right > .p-inputtext {
    padding-right: 2.5rem;
  }

  ::-webkit-input-placeholder {
    color: #71717a;
  }

  :-moz-placeholder {
    color: #71717a;
  }

  ::-moz-placeholder {
    color: #71717a;
  }

  :-ms-input-placeholder {
    color: #71717a;
  }

  .p-input-filled .p-inputtext {
    background-color: #fafafa;
  }
  .p-input-filled .p-inputtext:enabled:hover {
    background-color: #fafafa;
  }
  .p-input-filled .p-inputtext:enabled:focus {
    background-color: #ffffff;
  }

  .p-inputtext-sm .p-inputtext {
    font-size: 0.875rem;
    padding: 0.65625rem 0.65625rem;
  }

  .p-inputtext-lg .p-inputtext {
    font-size: 1.25rem;
    padding: 0.9375rem 0.9375rem;
  }

  .p-listbox {
    background: #ffffff;
    color: #3f3f46;
    border: 1px solid #d4d4d8;
    border-radius: 0.375rem;
    transition: none;
  }
  .p-listbox .p-listbox-header {
    padding: 0.5rem 0.75rem;
    border-bottom: 0 none;
    color: #3f3f46;
    background: #fafafa;
    margin: 0;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .p-listbox .p-listbox-header .p-listbox-filter {
    padding-right: 1.75rem;
  }
  .p-listbox .p-listbox-header .p-listbox-filter-icon {
    right: 0.75rem;
    color: #71717a;
  }
  .p-listbox .p-listbox-header .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-listbox .p-listbox-list {
    padding: 0.25rem 0;
    outline: 0 none;
  }
  .p-listbox .p-listbox-list .p-listbox-item {
    margin: 0;
    padding: 0.75rem 1rem;
    border: 0 none;
    color: #3f3f46;
    transition: none;
    border-radius: 0;
  }
  .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: #312e81;
    background: var(--primary-100);
  }
  .p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-listbox .p-listbox-list .p-listbox-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #3f3f46;
    background: #ffffff;
    font-weight: 600;
  }
  .p-listbox .p-listbox-list .p-listbox-empty-message {
    padding: 0.75rem 1rem;
    color: #3f3f46;
    background: transparent;
  }
  .p-listbox:not(.p-disabled) .p-listbox-item.p-highlight.p-focus {
    /* background: #e0e7ff; */
  }
  .p-listbox:not(.p-disabled)
    .p-listbox-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #18181b;
    background: #e5e7eb;
  }
  .p-listbox:not(.p-disabled)
    .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: #18181b;
    background: #f4f4f5;
  }
  .p-listbox.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
    border-color: var(--primary-color);
  }

  p-listbox.ng-dirty.ng-invalid > .p-listbox {
    border-color: #f0a9a7;
  }

  .p-multiselect {
    background: #ffffff;
    border: 1px solid #d4d4d8;
    transition: none;
    border-radius: 0.375rem;
  }
  .p-multiselect:not(.p-disabled):hover {
    border-color: #d4d4d8;
  }
  .p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
    border-color: var(--primary-color);
  }
  .p-multiselect .p-multiselect-label {
    padding: 0.75rem 0.75rem;
    transition: none;
  }
  .p-multiselect .p-multiselect-label.p-placeholder {
    color: var(--gray-200);
  }
  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.375rem 0.75rem;
    margin-right: 0.5rem;
    background: #e5e7eb;
    color: #3f3f46;
    border-radius: 16px;
  }
  .p-multiselect.p-multiselect-chip
    .p-multiselect-token
    .p-multiselect-token-icon {
    margin-left: 0.5rem;
  }
  .p-multiselect .p-multiselect-trigger {
    background: transparent;
    color: #71717a;
    width: 3rem;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 0.375rem 0.75rem;
  }

  .p-multiselect-clearable .p-multiselect-label-container {
    padding-right: 1.75rem;
  }
  .p-multiselect-clearable .p-multiselect-clear-icon {
    color: #71717a;
    right: 3rem;
  }

  .p-multiselect-panel {
    background: #ffffff;
    color: #3f3f46;
    border: 0 none;
    border-radius: 0.375rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .p-multiselect-panel .p-multiselect-header {
    padding: 0.5rem 0.75rem;
    border-bottom: 0 none;
    color: #3f3f46;
    background: #fafafa;
    margin: 0;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .p-multiselect-panel
    .p-multiselect-header
    .p-multiselect-filter-container
    .p-inputtext {
    padding-right: 1.75rem;
  }
  .p-multiselect-panel
    .p-multiselect-header
    .p-multiselect-filter-container
    .p-multiselect-filter-icon {
    right: 0.75rem;
    color: #71717a;
  }
  .p-multiselect-panel .p-multiselect-header .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    margin-left: 0.5rem;
    width: 2rem;
    height: 2rem;
    color: #71717a;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: none;
  }
  .p-multiselect-panel
    .p-multiselect-header
    .p-multiselect-close:enabled:hover {
    color: #18181b;
    border-color: transparent;
    background: #f4f4f5;
  }
  .p-multiselect-panel
    .p-multiselect-header
    .p-multiselect-close:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-multiselect-panel .p-multiselect-items {
    padding: 0.25rem 0;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.75rem 1rem;
    border: 0 none;
    color: #3f3f46;
    background: transparent;
    transition: none;
    border-radius: 0;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #312e81;
    background: var(--primary-100);
  }
  .p-multiselect-panel
    .p-multiselect-items
    .p-multiselect-item.p-highlight.p-focus {
    /* background: var(--primary-200); */
  }
  .p-multiselect-panel
    .p-multiselect-items
    .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #18181b;
    background: #e5e7eb;
  }
  .p-multiselect-panel
    .p-multiselect-items
    .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #18181b;
    background: #f4f4f5;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #3f3f46;
    background: #ffffff;
    font-weight: 600;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    padding: 0.75rem 1rem;
    color: #3f3f46;
    background: transparent;
  }

  .p-input-filled .p-multiselect {
    background: #fafafa;
  }
  .p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: #fafafa;
  }
  .p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: #ffffff;
  }

  p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
    border-color: #f0a9a7;
  }

  p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
    border-color: #f0a9a7;
  }

  .p-password-panel {
    padding: 1.25rem;
    background: #ffffff;
    color: #3f3f46;
    border: 0 none;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
  }
  .p-password-panel .p-password-meter {
    margin-bottom: 0.5rem;
    background: #e5e7eb;
  }
  .p-password-panel .p-password-meter .p-password-strength.weak {
    background: #ef4444;
  }
  .p-password-panel .p-password-meter .p-password-strength.medium {
    background: #f59e0b;
  }
  .p-password-panel .p-password-meter .p-password-strength.strong {
    background: #22c55e;
  }

  p-password.p-password-clearable .p-password-input {
    padding-right: 2.5rem;
  }
  p-password.p-password-clearable .p-password-clear-icon {
    color: #71717a;
    right: 0.75rem;
  }

  p-password.p-password-clearable.p-password-mask .p-password-input {
    padding-right: 4.25rem;
  }
  p-password.p-password-clearable.p-password-mask .p-password-clear-icon {
    color: #71717a;
    right: 2.5rem;
  }

  .p-radiobutton {
    width: 16px;
    height: 16px;
  }
  .p-radiobutton .p-radiobutton-box {
    border: 2px solid #d4d4d8;
    background: #ffffff;
    width: 16px;
    height: 16px;
    color: #3f3f46;
    border-radius: 50%;
    transition: none;
  }
  .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #d4d4d8;
  }
  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
    border-color: var(--primary-color);
  }
  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 6px;
    height: 6px;
    transition-duration: 0.2s;
    background-color: #ffffff;
  }
  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: var(--primary-color);
    background: var(--primary-color);
  }
  .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: var(--primary-color);
    background: var(--primary-color);
    color: #ffffff;
  }

  p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
    border-color: #f0a9a7;
  }

  .p-input-filled .p-radiobutton .p-radiobutton-box {
    background-color: #fafafa;
  }
  .p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: #fafafa;
  }
  .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: var(--primary-color);
  }
  .p-input-filled
    .p-radiobutton
    .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: var(--primary-color);
  }

  .p-radiobutton-label {
    margin-left: 0.5rem;
  }

  .p-rating {
    gap: 0.5rem;
  }
  .p-rating .p-rating-item .p-rating-icon {
    color: #3f3f46;
    transition: none;
    font-size: 1.143rem;
  }
  .p-rating .p-rating-item .p-rating-icon.p-icon {
    width: 1.143rem;
    height: 1.143rem;
  }
  .p-rating .p-rating-item .p-rating-icon.p-rating-cancel {
    color: #ef4444;
  }
  .p-rating .p-rating-item.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
    border-color: var(--primary-color);
  }
  .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
    color: var(--primary-color);
  }
  .p-rating:not(.p-disabled):not(.p-readonly)
    .p-rating-item:hover
    .p-rating-icon {
    color: var(--primary-color);
  }
  .p-rating:not(.p-disabled):not(.p-readonly)
    .p-rating-item:hover
    .p-rating-icon.p-rating-cancel {
    color: #dc2626;
  }

  .p-selectbutton .p-button {
    background: #ffffff;
    border: 1px solid #d4d4d8;
    color: #3f3f46;
    transition: none;
  }
  .p-selectbutton .p-button .p-button-icon-left,
  .p-selectbutton .p-button .p-button-icon-right {
    color: #71717a;
  }
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #f4f4f5;
    border-color: #d4d4d8;
    color: #3f3f46;
  }
  .p-selectbutton
    .p-button:not(.p-disabled):not(.p-highlight):hover
    .p-button-icon-left,
  .p-selectbutton
    .p-button:not(.p-disabled):not(.p-highlight):hover
    .p-button-icon-right {
    color: #71717a;
  }
  .p-selectbutton .p-button.p-highlight {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: #ffffff;
  }
  .p-selectbutton .p-button.p-highlight .p-button-icon-left,
  .p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: #ffffff;
  }
  .p-selectbutton .p-button.p-highlight:hover {
    background: var(--primary-600);
    border-color: var(--primary-600);
    color: #ffffff;
  }
  .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
  .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: #ffffff;
  }

  p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
    border-color: #f0a9a7;
  }

  .p-slider {
    background: #e5e7eb;
    border: 0 none;
    border-radius: 0.375rem;
  }
  .p-slider.p-slider-horizontal {
    height: 0.286rem;
  }
  .p-slider.p-slider-horizontal .p-slider-handle {
    margin-top: -0.5715rem;
    margin-left: -0.5715rem;
  }
  .p-slider.p-slider-vertical {
    width: 0.286rem;
  }
  .p-slider.p-slider-vertical .p-slider-handle {
    margin-left: -0.5715rem;
    margin-bottom: -0.5715rem;
  }
  .p-slider .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: #ffffff;
    border: 2px solid var(--primary-color);
    border-radius: 50%;
    transition: none;
  }
  .p-slider .p-slider-handle:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-slider .p-slider-range {
    background: var(--primary-color);
  }
  .p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: var(--primary-color);
    border-color: var(--primary-color);
  }
  .p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
    transition: none, left 0.2s;
  }
  .p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
    transition: width 0.2s;
  }
  .p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
    transition: none, bottom 0.2s;
  }
  .p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
    transition: height 0.2s;
  }

  .p-togglebutton.p-button {
    background: #ffffff;
    border: 1px solid #d4d4d8;
    color: #3f3f46;
    transition: none;
  }
  .p-togglebutton.p-button .p-button-icon-left,
  .p-togglebutton.p-button .p-button-icon-right {
    color: #71717a;
  }
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #f4f4f5;
    border-color: #d4d4d8;
    color: #3f3f46;
  }
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
    .p-button-icon-left,
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
    .p-button-icon-right {
    color: #71717a;
  }
  .p-togglebutton.p-button.p-highlight {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: #ffffff;
  }
  .p-togglebutton.p-button.p-highlight .p-button-icon-left,
  .p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: #ffffff;
  }
  .p-togglebutton.p-button.p-highlight:hover {
    background: var(--primary-600);
    border-color: var(--primary-600);
    color: #ffffff;
  }
  .p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
  .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
    color: #ffffff;
  }

  p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
    border-color: #f0a9a7;
  }

  .p-treeselect {
    background: #ffffff;
    border: 1px solid #d4d4d8;
    transition: none;
    border-radius: 0.375rem;
  }
  .p-treeselect:not(.p-disabled):hover {
    border-color: #d4d4d8;
  }
  .p-treeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
    border-color: var(--primary-color);
  }
  .p-treeselect .p-treeselect-label {
    padding: 0.75rem 0.75rem;
    transition: none;
  }
  .p-treeselect .p-treeselect-label.p-placeholder {
    color: #71717a;
  }
  .p-treeselect.p-treeselect-chip .p-treeselect-token {
    padding: 0.375rem 0.75rem;
    margin-right: 0.5rem;
    background: #e5e7eb;
    color: #3f3f46;
    border-radius: 16px;
  }
  .p-treeselect .p-treeselect-trigger {
    background: transparent;
    color: #71717a;
    width: 3rem;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
    border-color: #f0a9a7;
  }

  .p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
    padding: 0.375rem 0.75rem;
  }

  .p-treeselect-panel {
    background: #ffffff;
    color: #3f3f46;
    border: 0 none;
    border-radius: 0.375rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .p-treeselect-panel .p-treeselect-header {
    padding: 0.5rem 0.75rem;
    border-bottom: 0 none;
    color: #3f3f46;
    background: #fafafa;
    margin: 0;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
    margin-right: 0.5rem;
  }
  .p-treeselect-panel
    .p-treeselect-header
    .p-treeselect-filter-container
    .p-treeselect-filter {
    padding-right: 1.75rem;
  }
  .p-treeselect-panel
    .p-treeselect-header
    .p-treeselect-filter-container
    .p-treeselect-filter-icon {
    right: 0.75rem;
    color: #71717a;
  }
  .p-treeselect-panel
    .p-treeselect-header
    .p-treeselect-filter-container.p-treeselect-clearable-filter
    .p-treeselect-filter {
    padding-right: 3.5rem;
  }
  .p-treeselect-panel
    .p-treeselect-header
    .p-treeselect-filter-container.p-treeselect-clearable-filter
    .p-treeselect-filter-clear-icon {
    right: 2.5rem;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-close {
    width: 2rem;
    height: 2rem;
    color: #71717a;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: none;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
    color: #18181b;
    border-color: transparent;
    background: #f4f4f5;
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
    border: 0 none;
  }
  .p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
    padding: 0.75rem 1rem;
    color: #3f3f46;
    background: transparent;
  }

  .p-input-filled .p-treeselect {
    background: #fafafa;
  }
  .p-input-filled .p-treeselect:not(.p-disabled):hover {
    background-color: #fafafa;
  }
  .p-input-filled .p-treeselect:not(.p-disabled).p-focus {
    background-color: #ffffff;
  }

  p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
    padding-right: 1.75rem;
  }
  p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
    color: #71717a;
    right: 3rem;
  }

  .p-button {
    color: #ffffff;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 0.75rem 1rem;
    font-size: 1rem;
    transition: none;
    border-radius: 0.375rem;
  }
  .p-button:not(:disabled):hover {
    background: var(--primary-600);
    color: #ffffff;
    border-color: var(--primary-600);
  }
  .p-button:not(:disabled):active {
    background: var(--primary-600);
    color: #ffffff;
    border-color: var(--primary-600);
  }
  .p-button.p-button-outlined {
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid;
  }
  .p-button.p-button-outlined:not(:disabled):hover {
    background: var(--highlight-bg);
    color: var(--primary-color);
    border: 1px solid;
  }
  .p-button.p-button-outlined:not(:disabled):active {
    background: var(--primary-100);
    color: var(--primary-color);
    border: 1px solid;
  }
  .p-button.p-button-outlined.p-button-plain {
    color: #71717a;
    border-color: #71717a;
  }
  .p-button.p-button-outlined.p-button-plain:not(:disabled):hover {
    background: #f4f4f5;
    color: #71717a;
  }
  .p-button.p-button-outlined.p-button-plain:not(:disabled):active {
    background: #e5e7eb;
    color: #71717a;
  }
  .p-button.p-button-text {
    background-color: transparent;
    color: var(--primary-color);
    border-color: transparent;
  }
  .p-button.p-button-text:not(:disabled):hover {
    background: var(--highlight-bg);
    color: var(--primary-color);
    border-color: transparent;
  }
  .p-button.p-button-text:not(:disabled):active {
    background: var(--primary-100);
    color: var(--primary-color);
    border-color: transparent;
  }
  .p-button.p-button-text.p-button-plain {
    color: #71717a;
  }
  .p-button.p-button-text.p-button-plain:not(:disabled):hover {
    background: #f4f4f5;
    color: #71717a;
  }
  .p-button.p-button-text.p-button-plain:not(:disabled):active {
    background: #e5e7eb;
    color: #71717a;
  }
  .p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-button .p-button-label {
    transition-duration: 0.2s;
  }
  .p-button .p-button-icon-left {
    margin-right: 0.5rem;
  }
  .p-button .p-button-icon-right {
    margin-left: 0.5rem;
  }
  .p-button .p-button-icon-bottom {
    margin-top: 0.5rem;
  }
  .p-button .p-button-icon-top {
    margin-bottom: 0.5rem;
  }
  .p-button .p-badge {
    margin-left: 0.5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: var(--primary-color);
    background-color: #ffffff;
  }
  .p-button.p-button-raised {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .p-button.p-button-rounded {
    border-radius: 2rem;
  }
  .p-button.p-button-icon-only {
    width: 3rem;
    padding: 0.75rem 0;
  }
  .p-button.p-button-icon-only .p-button-icon-left,
  .p-button.p-button-icon-only .p-button-icon-right {
    margin: 0;
  }
  .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 3rem;
  }
  .p-button.p-button-sm {
    font-size: 0.875rem;
    padding: 0.65625rem 0.875rem;
  }
  .p-button.p-button-sm .p-button-icon {
    font-size: 0.875rem;
  }
  .p-button.p-button-lg {
    font-size: 1.25rem;
    padding: 0.9375rem 1.25rem;
  }
  .p-button.p-button-lg .p-button-icon {
    font-size: 1.25rem;
  }
  .p-button.p-button-loading-label-only .p-button-label {
    margin-left: 0.5rem;
  }
  .p-button.p-button-loading-label-only .p-button-loading-icon {
    margin-right: 0;
  }

  .p-fluid .p-button {
    width: 100%;
  }
  .p-fluid .p-button-icon-only {
    width: 3rem;
  }
  .p-fluid .p-buttonset {
    display: flex;
  }
  .p-fluid .p-buttonset .p-button {
    flex: 1;
  }

  .p-button.p-button-secondary,
  .p-buttonset.p-button-secondary > .p-button,
  .p-splitbutton.p-button-secondary > .p-button {
    color: #505d73;
    background: #eceef2;
    border: 1px solid #eceef2;
  }
  .p-button.p-button-secondary:not(:disabled):hover,
  .p-buttonset.p-button-secondary > .p-button:not(:disabled):hover,
  .p-splitbutton.p-button-secondary > .p-button:not(:disabled):hover {
    background: #e2e3e5;
    color: #505d73;
    border-color: #e2e3e5;
  }
  .p-button.p-button-secondary:not(:disabled):focus,
  .p-buttonset.p-button-secondary > .p-button:not(:disabled):focus,
  .p-splitbutton.p-button-secondary > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 0.2rem #c0c7d2;
  }
  .p-button.p-button-secondary:not(:disabled):active,
  .p-buttonset.p-button-secondary > .p-button:not(:disabled):active,
  .p-splitbutton.p-button-secondary > .p-button:not(:disabled):active {
    background: #d3d3d3;
    color: #505d73;
    border-color: #d3d3d3;
  }
  .p-button.p-button-secondary.p-button-outlined,
  .p-buttonset.p-button-secondary > .p-button.p-button-outlined,
  .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
    background-color: transparent;
    color: #eceef2;
    border: 1px solid;
  }
  .p-button.p-button-secondary.p-button-outlined:not(:disabled):hover,
  .p-buttonset.p-button-secondary
    > .p-button.p-button-outlined:not(:disabled):hover,
  .p-splitbutton.p-button-secondary
    > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(100, 116, 139, 0.04);
    color: #eceef2;
    border: 1px solid;
  }
  .p-button.p-button-secondary.p-button-outlined:not(:disabled):active,
  .p-buttonset.p-button-secondary
    > .p-button.p-button-outlined:not(:disabled):active,
  .p-splitbutton.p-button-secondary
    > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(100, 116, 139, 0.16);
    color: #eceef2;
    border: 1px solid;
  }
  .p-button.p-button-secondary.p-button-text,
  .p-buttonset.p-button-secondary > .p-button.p-button-text,
  .p-splitbutton.p-button-secondary > .p-button.p-button-text {
    background-color: transparent;
    color: #eceef2;
    border-color: transparent;
  }
  .p-button.p-button-secondary.p-button-text:not(:disabled):hover,
  .p-buttonset.p-button-secondary
    > .p-button.p-button-text:not(:disabled):hover,
  .p-splitbutton.p-button-secondary
    > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(100, 116, 139, 0.04);
    border-color: transparent;
    color: #eceef2;
  }
  .p-button.p-button-secondary.p-button-text:not(:disabled):active,
  .p-buttonset.p-button-secondary
    > .p-button.p-button-text:not(:disabled):active,
  .p-splitbutton.p-button-secondary
    > .p-button.p-button-text:not(:disabled):active {
    background: rgba(100, 116, 139, 0.16);
    border-color: transparent;
    color: #eceef2;
  }

  .p-button.p-button-info,
  .p-buttonset.p-button-info > .p-button,
  .p-splitbutton.p-button-info > .p-button {
    color: #ffffff;
    background: #3b82f6;
    border: 1px solid #3b82f6;
  }
  .p-button.p-button-info:not(:disabled):hover,
  .p-buttonset.p-button-info > .p-button:not(:disabled):hover,
  .p-splitbutton.p-button-info > .p-button:not(:disabled):hover {
    background: #2563eb;
    color: #ffffff;
    border-color: #2563eb;
  }
  .p-button.p-button-info:not(:disabled):focus,
  .p-buttonset.p-button-info > .p-button:not(:disabled):focus,
  .p-splitbutton.p-button-info > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 0.2rem #b1cdfb;
  }
  .p-button.p-button-info:not(:disabled):active,
  .p-buttonset.p-button-info > .p-button:not(:disabled):active,
  .p-splitbutton.p-button-info > .p-button:not(:disabled):active {
    background: #2563eb;
    color: #ffffff;
    border-color: #2563eb;
  }
  .p-button.p-button-info.p-button-outlined,
  .p-buttonset.p-button-info > .p-button.p-button-outlined,
  .p-splitbutton.p-button-info > .p-button.p-button-outlined {
    background-color: transparent;
    color: #3b82f6;
    border: 1px solid;
  }
  .p-button.p-button-info.p-button-outlined:not(:disabled):hover,
  .p-buttonset.p-button-info > .p-button.p-button-outlined:not(:disabled):hover,
  .p-splitbutton.p-button-info
    > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(59, 130, 246, 0.04);
    color: #3b82f6;
    border: 1px solid;
  }
  .p-button.p-button-info.p-button-outlined:not(:disabled):active,
  .p-buttonset.p-button-info
    > .p-button.p-button-outlined:not(:disabled):active,
  .p-splitbutton.p-button-info
    > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(59, 130, 246, 0.16);
    color: #3b82f6;
    border: 1px solid;
  }
  .p-button.p-button-info.p-button-text,
  .p-buttonset.p-button-info > .p-button.p-button-text,
  .p-splitbutton.p-button-info > .p-button.p-button-text {
    background-color: transparent;
    color: #3b82f6;
    border-color: transparent;
  }
  .p-button.p-button-info.p-button-text:not(:disabled):hover,
  .p-buttonset.p-button-info > .p-button.p-button-text:not(:disabled):hover,
  .p-splitbutton.p-button-info > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(59, 130, 246, 0.04);
    border-color: transparent;
    color: #3b82f6;
  }
  .p-button.p-button-info.p-button-text:not(:disabled):active,
  .p-buttonset.p-button-info > .p-button.p-button-text:not(:disabled):active,
  .p-splitbutton.p-button-info > .p-button.p-button-text:not(:disabled):active {
    background: rgba(59, 130, 246, 0.16);
    border-color: transparent;
    color: #3b82f6;
  }

  .p-button.p-button-success,
  .p-buttonset.p-button-success > .p-button,
  .p-splitbutton.p-button-success > .p-button {
    color: #ffffff;
    background: #22c55e;
    border: 1px solid #22c55e;
  }
  .p-button.p-button-success:not(:disabled):hover,
  .p-buttonset.p-button-success > .p-button:not(:disabled):hover,
  .p-splitbutton.p-button-success > .p-button:not(:disabled):hover {
    background: #16a34a;
    color: #ffffff;
    border-color: #16a34a;
  }
  .p-button.p-button-success:not(:disabled):focus,
  .p-buttonset.p-button-success > .p-button:not(:disabled):focus,
  .p-splitbutton.p-button-success > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 0.2rem #a0efbd;
  }
  .p-button.p-button-success:not(:disabled):active,
  .p-buttonset.p-button-success > .p-button:not(:disabled):active,
  .p-splitbutton.p-button-success > .p-button:not(:disabled):active {
    background: #16a34a;
    color: #ffffff;
    border-color: #16a34a;
  }
  .p-button.p-button-success.p-button-outlined,
  .p-buttonset.p-button-success > .p-button.p-button-outlined,
  .p-splitbutton.p-button-success > .p-button.p-button-outlined {
    background-color: transparent;
    color: #22c55e;
    border: 1px solid;
  }
  .p-button.p-button-success.p-button-outlined:not(:disabled):hover,
  .p-buttonset.p-button-success
    > .p-button.p-button-outlined:not(:disabled):hover,
  .p-splitbutton.p-button-success
    > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(34, 197, 94, 0.04);
    color: #22c55e;
    border: 1px solid;
  }
  .p-button.p-button-success.p-button-outlined:not(:disabled):active,
  .p-buttonset.p-button-success
    > .p-button.p-button-outlined:not(:disabled):active,
  .p-splitbutton.p-button-success
    > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(34, 197, 94, 0.16);
    color: #22c55e;
    border: 1px solid;
  }
  .p-button.p-button-success.p-button-text,
  .p-buttonset.p-button-success > .p-button.p-button-text,
  .p-splitbutton.p-button-success > .p-button.p-button-text {
    background-color: transparent;
    color: #22c55e;
    border-color: transparent;
  }
  .p-button.p-button-success.p-button-text:not(:disabled):hover,
  .p-buttonset.p-button-success > .p-button.p-button-text:not(:disabled):hover,
  .p-splitbutton.p-button-success
    > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(34, 197, 94, 0.04);
    border-color: transparent;
    color: #22c55e;
  }
  .p-button.p-button-success.p-button-text:not(:disabled):active,
  .p-buttonset.p-button-success > .p-button.p-button-text:not(:disabled):active,
  .p-splitbutton.p-button-success
    > .p-button.p-button-text:not(:disabled):active {
    background: rgba(34, 197, 94, 0.16);
    border-color: transparent;
    color: #22c55e;
  }

  .p-button.p-button-warning,
  .p-buttonset.p-button-warning > .p-button,
  .p-splitbutton.p-button-warning > .p-button {
    color: #ffffff;
    background: #f59e0b;
    border: 1px solid #f59e0b;
  }
  .p-button.p-button-warning:not(:disabled):hover,
  .p-buttonset.p-button-warning > .p-button:not(:disabled):hover,
  .p-splitbutton.p-button-warning > .p-button:not(:disabled):hover {
    background: #d97706;
    color: #ffffff;
    border-color: #d97706;
  }
  .p-button.p-button-warning:not(:disabled):focus,
  .p-buttonset.p-button-warning > .p-button:not(:disabled):focus,
  .p-splitbutton.p-button-warning > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 0.2rem #fbd89d;
  }
  .p-button.p-button-warning:not(:disabled):active,
  .p-buttonset.p-button-warning > .p-button:not(:disabled):active,
  .p-splitbutton.p-button-warning > .p-button:not(:disabled):active {
    background: #d97706;
    color: #ffffff;
    border-color: #d97706;
  }
  .p-button.p-button-warning.p-button-outlined,
  .p-buttonset.p-button-warning > .p-button.p-button-outlined,
  .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
    background-color: transparent;
    color: #f59e0b;
    border: 1px solid;
  }
  .p-button.p-button-warning.p-button-outlined:not(:disabled):hover,
  .p-buttonset.p-button-warning
    > .p-button.p-button-outlined:not(:disabled):hover,
  .p-splitbutton.p-button-warning
    > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(245, 158, 11, 0.04);
    color: #f59e0b;
    border: 1px solid;
  }
  .p-button.p-button-warning.p-button-outlined:not(:disabled):active,
  .p-buttonset.p-button-warning
    > .p-button.p-button-outlined:not(:disabled):active,
  .p-splitbutton.p-button-warning
    > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(245, 158, 11, 0.16);
    color: #f59e0b;
    border: 1px solid;
  }
  .p-button.p-button-warning.p-button-text,
  .p-buttonset.p-button-warning > .p-button.p-button-text,
  .p-splitbutton.p-button-warning > .p-button.p-button-text {
    background-color: transparent;
    color: #f59e0b;
    border-color: transparent;
  }
  .p-button.p-button-warning.p-button-text:not(:disabled):hover,
  .p-buttonset.p-button-warning > .p-button.p-button-text:not(:disabled):hover,
  .p-splitbutton.p-button-warning
    > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(245, 158, 11, 0.04);
    border-color: transparent;
    color: #f59e0b;
  }
  .p-button.p-button-warning.p-button-text:not(:disabled):active,
  .p-buttonset.p-button-warning > .p-button.p-button-text:not(:disabled):active,
  .p-splitbutton.p-button-warning
    > .p-button.p-button-text:not(:disabled):active {
    background: rgba(245, 158, 11, 0.16);
    border-color: transparent;
    color: #f59e0b;
  }

  .p-button.p-button-help,
  .p-buttonset.p-button-help > .p-button,
  .p-splitbutton.p-button-help > .p-button {
    color: #ffffff;
    background: #a855f7;
    border: 1px solid #a855f7;
  }
  .p-button.p-button-help:not(:disabled):hover,
  .p-buttonset.p-button-help > .p-button:not(:disabled):hover,
  .p-splitbutton.p-button-help > .p-button:not(:disabled):hover {
    background: #9333ea;
    color: #ffffff;
    border-color: #9333ea;
  }
  .p-button.p-button-help:not(:disabled):focus,
  .p-buttonset.p-button-help > .p-button:not(:disabled):focus,
  .p-splitbutton.p-button-help > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 0.2rem #dcbbfc;
  }
  .p-button.p-button-help:not(:disabled):active,
  .p-buttonset.p-button-help > .p-button:not(:disabled):active,
  .p-splitbutton.p-button-help > .p-button:not(:disabled):active {
    background: #9333ea;
    color: #ffffff;
    border-color: #9333ea;
  }
  .p-button.p-button-help.p-button-outlined,
  .p-buttonset.p-button-help > .p-button.p-button-outlined,
  .p-splitbutton.p-button-help > .p-button.p-button-outlined {
    background-color: transparent;
    color: #a855f7;
    border: 1px solid;
  }
  .p-button.p-button-help.p-button-outlined:not(:disabled):hover,
  .p-buttonset.p-button-help > .p-button.p-button-outlined:not(:disabled):hover,
  .p-splitbutton.p-button-help
    > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(168, 85, 247, 0.04);
    color: #a855f7;
    border: 1px solid;
  }
  .p-button.p-button-help.p-button-outlined:not(:disabled):active,
  .p-buttonset.p-button-help
    > .p-button.p-button-outlined:not(:disabled):active,
  .p-splitbutton.p-button-help
    > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(168, 85, 247, 0.16);
    color: #a855f7;
    border: 1px solid;
  }
  .p-button.p-button-help.p-button-text,
  .p-buttonset.p-button-help > .p-button.p-button-text,
  .p-splitbutton.p-button-help > .p-button.p-button-text {
    background-color: transparent;
    color: #a855f7;
    border-color: transparent;
  }
  .p-button.p-button-help.p-button-text:not(:disabled):hover,
  .p-buttonset.p-button-help > .p-button.p-button-text:not(:disabled):hover,
  .p-splitbutton.p-button-help > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(168, 85, 247, 0.04);
    border-color: transparent;
    color: #a855f7;
  }
  .p-button.p-button-help.p-button-text:not(:disabled):active,
  .p-buttonset.p-button-help > .p-button.p-button-text:not(:disabled):active,
  .p-splitbutton.p-button-help > .p-button.p-button-text:not(:disabled):active {
    background: rgba(168, 85, 247, 0.16);
    border-color: transparent;
    color: #a855f7;
  }

  .p-button.p-button-danger,
  .p-buttonset.p-button-danger > .p-button,
  .p-splitbutton.p-button-danger > .p-button {
    color: #ffffff;
    background: #ef4444;
    border: 1px solid #ef4444;
  }
  .p-button.p-button-danger:not(:disabled):hover,
  .p-buttonset.p-button-danger > .p-button:not(:disabled):hover,
  .p-splitbutton.p-button-danger > .p-button:not(:disabled):hover {
    background: #dc2626;
    color: #ffffff;
    border-color: #dc2626;
  }
  .p-button.p-button-danger:not(:disabled):focus,
  .p-buttonset.p-button-danger > .p-button:not(:disabled):focus,
  .p-splitbutton.p-button-danger > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 0.2rem #f9b4b4;
  }
  .p-button.p-button-danger:not(:disabled):active,
  .p-buttonset.p-button-danger > .p-button:not(:disabled):active,
  .p-splitbutton.p-button-danger > .p-button:not(:disabled):active {
    background: #dc2626;
    color: #ffffff;
    border-color: #dc2626;
  }
  .p-button.p-button-danger.p-button-outlined,
  .p-buttonset.p-button-danger > .p-button.p-button-outlined,
  .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
    background-color: transparent;
    color: #ef4444;
    border: 1px solid;
  }
  .p-button.p-button-danger.p-button-outlined:not(:disabled):hover,
  .p-buttonset.p-button-danger
    > .p-button.p-button-outlined:not(:disabled):hover,
  .p-splitbutton.p-button-danger
    > .p-button.p-button-outlined:not(:disabled):hover {
    background: rgba(239, 68, 68, 0.04);
    color: #ef4444;
    border: 1px solid;
  }
  .p-button.p-button-danger.p-button-outlined:not(:disabled):active,
  .p-buttonset.p-button-danger
    > .p-button.p-button-outlined:not(:disabled):active,
  .p-splitbutton.p-button-danger
    > .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(239, 68, 68, 0.16);
    color: #ef4444;
    border: 1px solid;
  }
  .p-button.p-button-danger.p-button-text,
  .p-buttonset.p-button-danger > .p-button.p-button-text,
  .p-splitbutton.p-button-danger > .p-button.p-button-text {
    background-color: transparent;
    color: #ef4444;
    border-color: transparent;
  }
  .p-button.p-button-danger.p-button-text:not(:disabled):hover,
  .p-buttonset.p-button-danger > .p-button.p-button-text:not(:disabled):hover,
  .p-splitbutton.p-button-danger
    > .p-button.p-button-text:not(:disabled):hover {
    background: rgba(239, 68, 68, 0.04);
    border-color: transparent;
    color: #ef4444;
  }
  .p-button.p-button-danger.p-button-text:not(:disabled):active,
  .p-buttonset.p-button-danger > .p-button.p-button-text:not(:disabled):active,
  .p-splitbutton.p-button-danger
    > .p-button.p-button-text:not(:disabled):active {
    background: rgba(239, 68, 68, 0.16);
    border-color: transparent;
    color: #ef4444;
  }

  .p-button.p-button-link {
    color: var(--primary-color);
    background: transparent;
    border: transparent;
  }
  .p-button.p-button-link:not(:disabled):hover {
    background: transparent;
    color: var(--primary-color);
    border-color: transparent;
  }
  .p-button.p-button-link:not(:disabled):hover .p-button-label {
    text-decoration: underline;
  }
  .p-button.p-button-link:not(:disabled):focus {
    background: transparent;
    box-shadow: 0 0 0 0.2rem #d3a78a;
    border-color: transparent;
  }
  .p-button.p-button-link:not(:disabled):active {
    background: transparent;
    color: var(--primary-color);
    border-color: transparent;
  }

  .p-speeddial-button.p-button.p-button-icon-only {
    width: 4rem;
    height: 4rem;
  }
  .p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
    font-size: 1.3rem;
  }
  .p-speeddial-button.p-button.p-button-icon-only .p-icon {
    width: 1.3rem;
    height: 1.3rem;
  }

  .p-speeddial-list {
    outline: 0 none;
  }

  .p-speeddial-item.p-focus > .p-speeddial-action {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }

  .p-speeddial-action {
    width: 3rem;
    height: 3rem;
    background: #3f3f46;
    color: #fff;
  }
  .p-speeddial-action:hover {
    background: #27272a;
    color: #fff;
  }

  .p-speeddial-direction-up .p-speeddial-item {
    margin: 0.25rem 0;
  }
  .p-speeddial-direction-up .p-speeddial-item:first-child {
    margin-bottom: 0.5rem;
  }

  .p-speeddial-direction-down .p-speeddial-item {
    margin: 0.25rem 0;
  }
  .p-speeddial-direction-down .p-speeddial-item:first-child {
    margin-top: 0.5rem;
  }

  .p-speeddial-direction-left .p-speeddial-item {
    margin: 0 0.25rem;
  }
  .p-speeddial-direction-left .p-speeddial-item:first-child {
    margin-right: 0.5rem;
  }

  .p-speeddial-direction-right .p-speeddial-item {
    margin: 0 0.25rem;
  }
  .p-speeddial-direction-right .p-speeddial-item:first-child {
    margin-left: 0.5rem;
  }

  .p-speeddial-circle .p-speeddial-item,
  .p-speeddial-semi-circle .p-speeddial-item,
  .p-speeddial-quarter-circle .p-speeddial-item {
    margin: 0;
  }
  .p-speeddial-circle .p-speeddial-item:first-child,
  .p-speeddial-circle .p-speeddial-item:last-child,
  .p-speeddial-semi-circle .p-speeddial-item:first-child,
  .p-speeddial-semi-circle .p-speeddial-item:last-child,
  .p-speeddial-quarter-circle .p-speeddial-item:first-child,
  .p-speeddial-quarter-circle .p-speeddial-item:last-child {
    margin: 0;
  }

  .p-speeddial-mask {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .p-splitbutton {
    border-radius: 0.375rem;
  }
  .p-splitbutton.p-button-outlined > .p-button {
    background-color: transparent;
    color: var(--primary-color);
    border: 1px solid;
  }
  .p-splitbutton.p-button-outlined > .p-button:not(:disabled):hover {
    background: rgba(79, 70, 229, 0.04);
    color: var(--primary-color);
  }
  .p-splitbutton.p-button-outlined > .p-button:not(:disabled):active {
    background: rgba(79, 70, 229, 0.16);
    color: var(--primary-color);
  }
  .p-splitbutton.p-button-outlined.p-button-plain > .p-button {
    color: #71717a;
    border-color: #71717a;
  }
  .p-splitbutton.p-button-outlined.p-button-plain
    > .p-button:not(:disabled):hover {
    background: #f4f4f5;
    color: #71717a;
  }
  .p-splitbutton.p-button-outlined.p-button-plain
    > .p-button:not(:disabled):active {
    background: #e5e7eb;
    color: #71717a;
  }
  .p-splitbutton.p-button-text > .p-button {
    background-color: transparent;
    color: var(--primary-color);
    border-color: transparent;
  }
  .p-splitbutton.p-button-text > .p-button:not(:disabled):hover {
    background: rgba(79, 70, 229, 0.04);
    color: var(--primary-color);
    border-color: transparent;
  }
  .p-splitbutton.p-button-text > .p-button:not(:disabled):active {
    background: rgba(79, 70, 229, 0.16);
    color: var(--primary-color);
    border-color: transparent;
  }
  .p-splitbutton.p-button-text.p-button-plain > .p-button {
    color: #71717a;
  }
  .p-splitbutton.p-button-text.p-button-plain > .p-button:not(:disabled):hover {
    background: #f4f4f5;
    color: #71717a;
  }
  .p-splitbutton.p-button-text.p-button-plain
    > .p-button:not(:disabled):active {
    background: #e5e7eb;
    color: #71717a;
  }
  .p-splitbutton.p-button-raised {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .p-splitbutton.p-button-rounded {
    border-radius: 2rem;
  }
  .p-splitbutton.p-button-rounded > .p-button {
    border-radius: 2rem;
  }
  .p-splitbutton.p-button-sm > .p-button {
    font-size: 0.875rem;
    padding: 0.65625rem 0.875rem;
  }
  .p-splitbutton.p-button-sm > .p-button .p-button-icon {
    font-size: 0.875rem;
  }
  .p-splitbutton.p-button-lg > .p-button {
    font-size: 1.25rem;
    padding: 0.9375rem 1.25rem;
  }
  .p-splitbutton.p-button-lg > .p-button .p-button-icon {
    font-size: 1.25rem;
  }

  .p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
    background-color: transparent;
    color: #64748b;
    border: 1px solid;
  }
  .p-splitbutton.p-button-secondary.p-button-outlined
    > .p-button:not(:disabled):hover {
    background: rgba(100, 116, 139, 0.04);
    color: #64748b;
  }
  .p-splitbutton.p-button-secondary.p-button-outlined
    > .p-button:not(:disabled):active {
    background: rgba(100, 116, 139, 0.16);
    color: #64748b;
  }
  .p-splitbutton.p-button-secondary.p-button-text > .p-button {
    background-color: transparent;
    color: #64748b;
    border-color: transparent;
  }
  .p-splitbutton.p-button-secondary.p-button-text
    > .p-button:not(:disabled):hover {
    background: rgba(100, 116, 139, 0.04);
    border-color: transparent;
    color: #64748b;
  }
  .p-splitbutton.p-button-secondary.p-button-text
    > .p-button:not(:disabled):active {
    background: rgba(100, 116, 139, 0.16);
    border-color: transparent;
    color: #64748b;
  }

  .p-splitbutton.p-button-info.p-button-outlined > .p-button {
    background-color: transparent;
    color: #3b82f6;
    border: 1px solid;
  }
  .p-splitbutton.p-button-info.p-button-outlined
    > .p-button:not(:disabled):hover {
    background: rgba(59, 130, 246, 0.04);
    color: #3b82f6;
  }
  .p-splitbutton.p-button-info.p-button-outlined
    > .p-button:not(:disabled):active {
    background: rgba(59, 130, 246, 0.16);
    color: #3b82f6;
  }
  .p-splitbutton.p-button-info.p-button-text > .p-button {
    background-color: transparent;
    color: #3b82f6;
    border-color: transparent;
  }
  .p-splitbutton.p-button-info.p-button-text > .p-button:not(:disabled):hover {
    background: rgba(59, 130, 246, 0.04);
    border-color: transparent;
    color: #3b82f6;
  }
  .p-splitbutton.p-button-info.p-button-text > .p-button:not(:disabled):active {
    background: rgba(59, 130, 246, 0.16);
    border-color: transparent;
    color: #3b82f6;
  }

  .p-splitbutton.p-button-success.p-button-outlined > .p-button {
    background-color: transparent;
    color: #22c55e;
    border: 1px solid;
  }
  .p-splitbutton.p-button-success.p-button-outlined
    > .p-button:not(:disabled):hover {
    background: rgba(34, 197, 94, 0.04);
    color: #22c55e;
  }
  .p-splitbutton.p-button-success.p-button-outlined
    > .p-button:not(:disabled):active {
    background: rgba(34, 197, 94, 0.16);
    color: #22c55e;
  }
  .p-splitbutton.p-button-success.p-button-text > .p-button {
    background-color: transparent;
    color: #22c55e;
    border-color: transparent;
  }
  .p-splitbutton.p-button-success.p-button-text
    > .p-button:not(:disabled):hover {
    background: rgba(34, 197, 94, 0.04);
    border-color: transparent;
    color: #22c55e;
  }
  .p-splitbutton.p-button-success.p-button-text
    > .p-button:not(:disabled):active {
    background: rgba(34, 197, 94, 0.16);
    border-color: transparent;
    color: #22c55e;
  }

  .p-splitbutton.p-button-warning.p-button-outlined > .p-button {
    background-color: transparent;
    color: #f59e0b;
    border: 1px solid;
  }
  .p-splitbutton.p-button-warning.p-button-outlined
    > .p-button:not(:disabled):hover {
    background: rgba(245, 158, 11, 0.04);
    color: #f59e0b;
  }
  .p-splitbutton.p-button-warning.p-button-outlined
    > .p-button:not(:disabled):active {
    background: rgba(245, 158, 11, 0.16);
    color: #f59e0b;
  }
  .p-splitbutton.p-button-warning.p-button-text > .p-button {
    background-color: transparent;
    color: #f59e0b;
    border-color: transparent;
  }
  .p-splitbutton.p-button-warning.p-button-text
    > .p-button:not(:disabled):hover {
    background: rgba(245, 158, 11, 0.04);
    border-color: transparent;
    color: #f59e0b;
  }
  .p-splitbutton.p-button-warning.p-button-text
    > .p-button:not(:disabled):active {
    background: rgba(245, 158, 11, 0.16);
    border-color: transparent;
    color: #f59e0b;
  }

  .p-splitbutton.p-button-help.p-button-outlined > .p-button {
    background-color: transparent;
    color: #a855f7;
    border: 1px solid;
  }
  .p-splitbutton.p-button-help.p-button-outlined
    > .p-button:not(:disabled):hover {
    background: rgba(168, 85, 247, 0.04);
    color: #a855f7;
  }
  .p-splitbutton.p-button-help.p-button-outlined
    > .p-button:not(:disabled):active {
    background: rgba(168, 85, 247, 0.16);
    color: #a855f7;
  }
  .p-splitbutton.p-button-help.p-button-text > .p-button {
    background-color: transparent;
    color: #a855f7;
    border-color: transparent;
  }
  .p-splitbutton.p-button-help.p-button-text > .p-button:not(:disabled):hover {
    background: rgba(168, 85, 247, 0.04);
    border-color: transparent;
    color: #a855f7;
  }
  .p-splitbutton.p-button-help.p-button-text > .p-button:not(:disabled):active {
    background: rgba(168, 85, 247, 0.16);
    border-color: transparent;
    color: #a855f7;
  }

  .p-splitbutton.p-button-danger.p-button-outlined > .p-button {
    background-color: transparent;
    color: #ef4444;
    border: 1px solid;
  }
  .p-splitbutton.p-button-danger.p-button-outlined
    > .p-button:not(:disabled):hover {
    background: rgba(239, 68, 68, 0.04);
    color: #ef4444;
  }
  .p-splitbutton.p-button-danger.p-button-outlined
    > .p-button:not(:disabled):active {
    background: rgba(239, 68, 68, 0.16);
    color: #ef4444;
  }
  .p-splitbutton.p-button-danger.p-button-text > .p-button {
    background-color: transparent;
    color: #ef4444;
    border-color: transparent;
  }
  .p-splitbutton.p-button-danger.p-button-text
    > .p-button:not(:disabled):hover {
    background: rgba(239, 68, 68, 0.04);
    border-color: transparent;
    color: #ef4444;
  }
  .p-splitbutton.p-button-danger.p-button-text
    > .p-button:not(:disabled):active {
    background: rgba(239, 68, 68, 0.16);
    border-color: transparent;
    color: #ef4444;
  }

  .p-carousel .p-carousel-content .p-carousel-prev,
  .p-carousel .p-carousel-content .p-carousel-next {
    width: 2rem;
    height: 2rem;
    color: #71717a;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: none;
    margin: 0.5rem;
  }
  .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
  .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: #18181b;
    border-color: transparent;
    background: #f4f4f5;
  }
  .p-carousel .p-carousel-content .p-carousel-prev:focus-visible,
  .p-carousel .p-carousel-content .p-carousel-next:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-carousel .p-carousel-indicators {
    padding: 1rem;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #f4f4f5;
    width: 2rem;
    height: 0.5rem;
    transition: none;
    border-radius: 0;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
    background: #e5e7eb;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background: #eef2ff;
    color: #312e81;
  }

  .p-datatable .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-datatable .p-paginator-bottom {
    border-radius: 0;
  }
  .p-datatable .p-datatable-header {
    /* background: #fafafa; */
    color: #3f3f46;
    /* border: 1px solid #f4f4f5; */
    border-width: 1px 0 1px 0;
    padding: 1.25rem 1.25rem;
    font-weight: 600;
  }
  .p-datatable .p-datatable-footer {
    background: #fafafa;
    color: #3f3f46;
    border: 1px solid #f4f4f5;
    border-width: 0 0 1px 0;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
  }
  .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 0.75rem 1.5rem;
    /* border: 1px solid #f4f4f5; */
    border-width: 0 0 1px 0;
    font-weight: 500;
    color: #6b7280;
    /* background: #fafafa; */
    transition: none;
  }
  .p-datatable .p-datatable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #f4f4f5;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #3f3f46;
    background: #fafafa;
  }
  .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #71717a;
    margin-left: 0.5rem;
  }
  .p-datatable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #312e81;
    background: #eef2ff;
    margin-left: 0.5rem;
  }
  .p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: #f4f4f5;
    color: #3f3f46;
  }
  .p-datatable
    .p-sortable-column:not(.p-highlight):hover
    .p-sortable-column-icon {
    color: #71717a;
  }
  .p-datatable .p-sortable-column.p-highlight {
    background: #fafafa;
    color: var(--primary-color);
  }
  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: var(--primary-color);
  }
  .p-datatable .p-sortable-column.p-highlight:hover {
    background: #f4f4f5;
    color: var(--primary-color);
  }
  .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: var(--primary-color);
  }
  .p-datatable .p-sortable-column:focus-visible {
    box-shadow: inset 0 0 0 0.15rem #d3a78a;
    outline: 0 none;
  }
  .p-datatable .p-datatable-tbody > tr {
    background: #ffffff;
    color: #3f3f46;
    transition: none;
  }
  .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #f4f4f5;
    border-width: 0 0 1px 0;
    padding: 1rem 1.5rem;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
    width: 2rem;
    height: 2rem;
    color: #71717a;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: none;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
    color: #18181b;
    border-color: transparent;
    background: #f4f4f5;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus-visible,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus-visible,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus-visible,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
    margin-right: 0.5rem;
  }
  .p-datatable .p-datatable-tbody > tr:focus-visible {
    outline: 0.15rem solid #d3a78a;
    outline-offset: -0.15rem;
  }
  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #eef2ff;
    color: #312e81;
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 #eef2ff;
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 #eef2ff;
  }
  .p-datatable.p-datatable-hoverable-rows
    .p-datatable-tbody
    > tr:not(.p-highlight):hover {
    background: #f4f4f5;
    color: #3f3f46;
  }
  .p-datatable .p-column-resizer-helper {
    background: var(--primary-color);
  }
  .p-datatable .p-datatable-scrollable-header,
  .p-datatable .p-datatable-scrollable-footer {
    background: #fafafa;
  }
  .p-datatable.p-datatable-scrollable
    > .p-datatable-wrapper
    > .p-datatable-table
    > .p-datatable-thead,
  .p-datatable.p-datatable-scrollable
    > .p-datatable-wrapper
    > .p-datatable-table
    > .p-datatable-tfoot,
  .p-datatable.p-datatable-scrollable
    > .p-datatable-wrapper
    > .p-scroller-viewport
    > .p-scroller
    > .p-datatable-table
    > .p-datatable-thead,
  .p-datatable.p-datatable-scrollable
    > .p-datatable-wrapper
    > .p-scroller-viewport
    > .p-scroller
    > .p-datatable-table
    > .p-datatable-tfoot {
    background-color: #fafafa;
  }
  .p-datatable .p-datatable-loading-icon {
    font-size: 5rem;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
    border-width: 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    border-width: 1px 0 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
    border-width: 1px 1px 0 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
    border-width: 1px 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines
    .p-datatable-tbody
    > tr:last-child
    > td:last-child {
    border-width: 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
    border-width: 1px 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
    border-width: 1px 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines
    .p-datatable-thead
    + .p-datatable-tfoot
    > tr
    > td {
    border-width: 0 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines
    .p-datatable-thead
    + .p-datatable-tfoot
    > tr
    > td:last-child {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
      .p-datatable-tbody
    )
    .p-datatable-tbody
    > tr
    > td {
    border-width: 0 0 1px 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
      .p-datatable-tbody
    )
    .p-datatable-tbody
    > tr
    > td:last-child {
    border-width: 0 1px 1px 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(
      .p-datatable-tfoot
    )
    .p-datatable-tbody
    > tr:last-child
    > td {
    border-width: 0 0 0 1px;
  }
  .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(
      .p-datatable-tfoot
    )
    .p-datatable-tbody
    > tr:last-child
    > td:last-child {
    border-width: 0 1px 0 1px;
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    background: #fcfcfc;
  }
  .p-datatable.p-datatable-striped
    .p-datatable-tbody
    > tr:nth-child(even).p-highlight {
    background: #eef2ff;
    color: #312e81;
  }
  .p-datatable.p-datatable-striped
    .p-datatable-tbody
    > tr:nth-child(even).p-highlight
    .p-row-toggler {
    color: #312e81;
  }
  .p-datatable.p-datatable-striped
    .p-datatable-tbody
    > tr:nth-child(even).p-highlight
    .p-row-toggler:hover {
    color: #312e81;
  }
  .p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0.625rem 0.625rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 0.375rem 0.75rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: 0.5rem 0.75rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
    padding: 0.375rem 0.75rem;
  }
  .p-datatable.p-datatable-sm .p-datatable-footer {
    padding: 0.375rem 0.75rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-header {
    padding: 1.5625rem 1.5625rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
    padding: 0.9375rem 1.875rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
    padding: 1.25rem 1.875rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
    padding: 0.9375rem 1.875rem;
  }
  .p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 0.9375rem 1.875rem;
  }

  .p-dataview .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-dataview .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-dataview .p-dataview-header {
    background: #fafafa;
    color: #3f3f46;
    border: 1px solid #f4f4f5;
    border-width: 1px 0 1px 0;
    padding: 1.25rem 1.25rem;
    font-weight: 600;
  }
  .p-dataview .p-dataview-content {
    background: #ffffff;
    color: #3f3f46;
    border: 0 none;
    padding: 0;
  }
  .p-dataview .p-dataview-footer {
    background: #fafafa;
    color: #3f3f46;
    border: 1px solid #f4f4f5;
    border-width: 0 0 1px 0;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .p-dataview .p-dataview-loading-icon {
    font-size: 2rem;
  }
  .p-dataview .p-dataview-emptymessage {
    padding: 1.25rem;
  }

  .p-column-filter-row .p-column-filter-menu-button,
  .p-column-filter-row .p-column-filter-clear-button {
    margin-left: 0.5rem;
  }

  .p-column-filter-menu-button {
    width: 2rem;
    height: 2rem;
    color: #71717a;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: none;
  }
  .p-column-filter-menu-button:hover {
    color: #18181b;
    border-color: transparent;
    background: #f4f4f5;
  }
  .p-column-filter-menu-button.p-column-filter-menu-button-open,
  .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    background: #f4f4f5;
    color: #18181b;
  }
  .p-column-filter-menu-button.p-column-filter-menu-button-active,
  .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: #eef2ff;
    color: #312e81;
  }
  .p-column-filter-menu-button:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }

  .p-column-filter-clear-button {
    width: 2rem;
    height: 2rem;
    color: #71717a;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: none;
  }
  .p-column-filter-clear-button:hover {
    color: #18181b;
    border-color: transparent;
    background: #f4f4f5;
  }
  .p-column-filter-clear-button:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }

  .p-column-filter-overlay {
    background: #ffffff;
    color: #3f3f46;
    border: 0 none;
    border-radius: 0.375rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    min-width: 12.5rem;
  }
  .p-column-filter-overlay .p-column-filter-row-items {
    padding: 0.25rem 0;
  }
  .p-column-filter-overlay
    .p-column-filter-row-items
    .p-column-filter-row-item {
    margin: 0;
    padding: 0.75rem 1rem;
    border: 0 none;
    color: #3f3f46;
    background: transparent;
    transition: none;
    border-radius: 0;
  }
  .p-column-filter-overlay
    .p-column-filter-row-items
    .p-column-filter-row-item.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }
  .p-column-filter-overlay
    .p-column-filter-row-items
    .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
    color: #18181b;
    background: #f4f4f5;
  }
  .p-column-filter-overlay
    .p-column-filter-row-items
    .p-column-filter-row-item:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #d3a78a;
  }
  .p-column-filter-overlay
    .p-column-filter-row-items
    .p-column-filter-separator {
    border-top: 1px solid #f3f4f6;
    margin: 0.25rem 0;
  }

  .p-column-filter-overlay-menu .p-column-filter-operator {
    padding: 0.5rem 0.75rem;
    border-bottom: 0 none;
    color: #3f3f46;
    background: #fafafa;
    margin: 0;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: 1.25rem;
    border-bottom: 1px solid #f3f4f6;
  }
  .p-column-filter-overlay-menu
    .p-column-filter-constraint
    .p-column-filter-matchmode-dropdown {
    margin-bottom: 0.5rem;
  }
  .p-column-filter-overlay-menu
    .p-column-filter-constraint
    .p-column-filter-remove-button {
    margin-top: 0.5rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0 none;
  }
  .p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: 0.75rem 1.25rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: 1.25rem;
  }

  .p-orderlist .p-orderlist-controls {
    padding: 1.25rem;
  }
  .p-orderlist .p-orderlist-controls .p-button {
    margin-bottom: 0.5rem;
  }
  .p-orderlist .p-orderlist-header {
    background: #fafafa;
    color: #3f3f46;
    border: 1px solid #e5e7eb;
    padding: 1.25rem;
    border-bottom: 0 none;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .p-orderlist .p-orderlist-header .p-orderlist-title {
    font-weight: 700;
  }
  .p-orderlist .p-orderlist-filter-container {
    padding: 1.25rem;
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-bottom: 0 none;
  }
  .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
    padding-right: 1.75rem;
  }
  .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
    right: 0.75rem;
    color: #71717a;
  }
  .p-orderlist .p-orderlist-list {
    border: 1px solid #e5e7eb;
    background: #ffffff;
    color: #3f3f46;
    padding: 0.25rem 0;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    outline: 0 none;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0.75rem 1rem;
    margin: 0;
    border: 0 none;
    color: #3f3f46;
    background: transparent;
    transition: none;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
    background: #f4f4f5;
    color: #18181b;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item.p-focus {
    color: #18181b;
    background: #e5e7eb;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight.p-focus {
    background: #e0e7ff;
  }
  .p-orderlist .p-orderlist-list .p-orderlist-empty-message {
    padding: 0.75rem 1rem;
    color: #3f3f46;
  }
  .p-orderlist
    .p-orderlist-list:not(.cdk-drop-list-dragging)
    .p-orderlist-item:not(.p-highlight):hover {
    background: #f4f4f5;
    color: #18181b;
  }
  .p-orderlist.p-orderlist-striped
    .p-orderlist-list
    .p-orderlist-item:nth-child(even) {
    background: #fcfcfc;
  }
  .p-orderlist.p-orderlist-striped
    .p-orderlist-list
    .p-orderlist-item:nth-child(even):hover {
    background: #f4f4f5;
  }

  .p-orderlist-item.cdk-drag-preview {
    padding: 0.75rem 1rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border: 0 none;
    color: #3f3f46;
    background: #ffffff;
    margin: 0;
  }

  .p-organizationchart
    .p-organizationchart-node-content.p-organizationchart-selectable-node:not(
      .p-highlight
    ):hover {
    background: #f4f4f5;
    color: #18181b;
  }
  .p-organizationchart .p-organizationchart-node-content.p-highlight {
    background: #eef2ff;
    color: #312e81;
  }
  .p-organizationchart
    .p-organizationchart-node-content.p-highlight
    .p-node-toggler
    i {
    color: #6f91ff;
  }
  .p-organizationchart .p-organizationchart-line-down {
    background: #e5e7eb;
  }
  .p-organizationchart .p-organizationchart-line-left {
    border-right: 1px solid #e5e7eb;
    border-color: #e5e7eb;
  }
  .p-organizationchart .p-organizationchart-line-top {
    border-top: 1px solid #e5e7eb;
    border-color: #e5e7eb;
  }
  .p-organizationchart .p-organizationchart-node-content {
    border: 1px solid #e5e7eb;
    background: #ffffff;
    color: #3f3f46;
    padding: 1.25rem;
  }
  .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    color: inherit;
    border-radius: 50%;
  }
  .p-organizationchart
    .p-organizationchart-node-content
    .p-node-toggler:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }

  .p-paginator {
    background: #ffffff;
    color: #6b7280;
    border: solid #f3f4f6;
    border-width: 0;
    padding: 0.5rem 1rem;
  }
  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    background-color: transparent;
    border: 0 none;
    color: #6b7280;
    min-width: 2rem;
    height: 2rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
  }
  .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
  .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: var(--primary-100);
    border-color: transparent;
  }
  .p-paginator .p-dropdown {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 3rem;
  }
  .p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
  }
  .p-paginator .p-paginator-page-input {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .p-paginator .p-paginator-page-input .p-inputtext {
    max-width: 3rem;
  }
  .p-paginator .p-paginator-current {
    background-color: transparent;
    border: 0 none;
    color: #6b7280;
    min-width: 3rem;
    height: 3rem;
    margin: 0.143rem;
    padding: 0 0.5rem;
  }
  .p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: #6b7280;
    min-width: 2.5rem;
    height: 2.5rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--primary-color);
    border-color: #eef2ff;
    color: #ffffff;
  }
  .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: var(--primary-100);
    border-color: transparent;
  }

  .p-picklist .p-picklist-buttons {
    padding: 1.25rem;
  }
  .p-picklist .p-picklist-buttons .p-button {
    margin-bottom: 0.5rem;
  }
  .p-picklist .p-picklist-header {
    background: #fafafa;
    color: #3f3f46;
    border: 1px solid #e5e7eb;
    padding: 1.25rem;
    border-bottom: 0 none;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .p-picklist .p-picklist-header .p-picklist-title {
    font-weight: 700;
  }
  .p-picklist .p-picklist-filter-container {
    padding: 1.25rem;
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-bottom: 0 none;
  }
  .p-picklist .p-picklist-filter-container .p-picklist-filter-input {
    padding-right: 1.75rem;
  }
  .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
    right: 0.75rem;
    color: #71717a;
  }
  .p-picklist .p-picklist-list {
    border: 1px solid #e5e7eb;
    background: #ffffff;
    color: #3f3f46;
    padding: 0.25rem 0;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    outline: 0 none;
  }
  .p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.75rem 1rem;
    margin: 0;
    border: 0 none;
    color: #3f3f46;
    background: transparent;
    transition: none;
  }
  .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
    background: #f4f4f5;
    color: #18181b;
  }
  .p-picklist .p-picklist-list .p-picklist-item.p-focus {
    color: #18181b;
    background: #e5e7eb;
  }
  .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: #312e81;
    background: #eef2ff;
  }
  .p-picklist .p-picklist-list .p-picklist-item.p-highlight.p-focus {
    background: #e0e7ff;
  }
  .p-picklist .p-picklist-list .p-picklist-empty-message {
    padding: 0.75rem 1rem;
    color: #3f3f46;
  }
  .p-picklist
    .p-picklist-list:not(.cdk-drop-list-dragging)
    .p-picklist-item:not(.p-highlight):hover {
    background: #f4f4f5;
    color: #18181b;
  }
  .p-picklist.p-picklist-striped
    .p-picklist-list
    .p-picklist-item:nth-child(even) {
    background: #fcfcfc;
  }
  .p-picklist.p-picklist-striped
    .p-picklist-list
    .p-picklist-item:nth-child(even):hover {
    background: #f4f4f5;
  }

  .p-picklist-item.cdk-drag-preview {
    padding: 0.75rem 1rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border: 0 none;
    color: #3f3f46;
    background: #ffffff;
    margin: 0;
  }

  .p-timeline .p-timeline-event-marker {
    border: 2px solid var(--primary-color);
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: #ffffff;
  }
  .p-timeline .p-timeline-event-connector {
    background-color: #e5e7eb;
  }
  .p-timeline.p-timeline-vertical .p-timeline-event-opposite,
  .p-timeline.p-timeline-vertical .p-timeline-event-content {
    padding: 0 1rem;
  }
  .p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 2px;
  }
  .p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
  .p-timeline.p-timeline-horizontal .p-timeline-event-content {
    padding: 1rem 0;
  }
  .p-timeline.p-timeline-horizontal .p-timeline-event-connector {
    height: 2px;
  }

  .p-tree {
    border: 1px solid #e5e7eb;
    background: #ffffff;
    color: #3f3f46;
    padding: 1.25rem;
    border-radius: 0.375rem;
  }
  .p-tree .p-tree-container .p-treenode {
    padding: 0.143rem;
    outline: 0 none;
  }
  .p-tree .p-tree-container .p-treenode:focus > .p-treenode-content {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #d3a78a;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: 0.375rem;
    transition: none;
    padding: 0;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
    color: #71717a;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: none;
  }
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content
    .p-tree-toggler:enabled:hover {
    color: #18181b;
    border-color: transparent;
    background: #f4f4f5;
  }
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content
    .p-tree-toggler:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    margin-right: 0.5rem;
    color: #71717a;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content
    .p-checkbox
    .p-indeterminate
    .p-checkbox-icon {
    color: #3f3f46;
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: #eef2ff;
    color: #312e81;
  }
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight
    .p-tree-toggler,
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight
    .p-treenode-icon {
    color: #312e81;
  }
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight
    .p-tree-toggler:hover,
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-highlight
    .p-treenode-icon:hover {
    color: #312e81;
  }
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #f4f4f5;
    color: #18181b;
  }
  .p-tree
    .p-tree-container
    .p-treenode
    .p-treenode-content.p-treenode-dragover {
    background: #f4f4f5;
    color: #18181b;
  }
  .p-tree .p-tree-filter-container {
    margin-bottom: 0.5rem;
  }
  .p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 1.75rem;
  }
  .p-tree .p-tree-filter-container .p-tree-filter-icon {
    right: 0.75rem;
    color: #71717a;
  }
  .p-tree .p-treenode-children {
    padding: 0 0 0 1rem;
  }
  .p-tree .p-tree-loading-icon {
    font-size: 2rem;
  }
  .p-tree .p-tree-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
    background-color: #8ba7ff;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
    border-radius: 0.375rem;
    border: 1px solid #e5e7eb;
    background-color: #ffffff;
    color: #3f3f46;
    padding: 0;
    transition: none;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
    background-color: #eef2ff;
    color: #312e81;
  }
  .p-tree.p-tree-horizontal
    .p-treenode
    .p-treenode-content.p-highlight
    .p-treenode-icon {
    color: #312e81;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0.5rem;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-tree.p-tree-horizontal
    .p-treenode
    .p-treenode-content
    .p-treenode-label:not(.p-highlight):hover {
    background-color: inherit;
    color: inherit;
  }
  .p-tree.p-tree-horizontal
    .p-treenode
    .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #f4f4f5;
    color: #18181b;
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }

  .p-treetable .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-treetable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .p-treetable .p-treetable-header {
    background: #fafafa;
    color: #3f3f46;
    border: 1px solid #f4f4f5;
    border-width: 1px 0 1px 0;
    padding: 1.25rem 1.25rem;
    font-weight: 600;
  }
  .p-treetable .p-treetable-footer {
    background: #fafafa;
    color: #3f3f46;
    border: 1px solid #f4f4f5;
    border-width: 0 0 1px 0;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
  }
  .p-treetable .p-treetable-thead > tr > th {
    text-align: left;
    padding: 0.75rem 1.5rem;
    border: 1px solid #f4f4f5;
    border-width: 0 0 1px 0;
    font-weight: 500;
    color: #6b7280;
    background: #fafafa;
    transition: none;
  }
  .p-treetable .p-treetable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #f4f4f5;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #3f3f46;
    background: #fafafa;
  }
  .p-treetable .p-sortable-column {
    outline-color: #d3a78a;
  }
  .p-treetable .p-sortable-column .p-sortable-column-icon {
    color: #71717a;
    margin-left: 0.5rem;
  }
  .p-treetable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #312e81;
    background: #eef2ff;
    margin-left: 0.5rem;
  }
  .p-treetable .p-sortable-column:not(.p-highlight):hover {
    background: #f4f4f5;
    color: #3f3f46;
  }
  .p-treetable
    .p-sortable-column:not(.p-highlight):hover
    .p-sortable-column-icon {
    color: #71717a;
  }
  .p-treetable .p-sortable-column.p-highlight {
    background: #fafafa;
    color: var(--primary-color);
  }
  .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: var(--primary-color);
  }
  .p-treetable .p-treetable-tbody > tr {
    background: #ffffff;
    color: #3f3f46;
    transition: none;
  }
  .p-treetable .p-treetable-tbody > tr > td {
    text-align: left;
    border: 1px solid #f4f4f5;
    border-width: 0 0 1px 0;
    padding: 1rem 1.5rem;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
    width: 2rem;
    height: 2rem;
    color: #71717a;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: none;
    margin-right: 0.5rem;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
    color: #18181b;
    border-color: transparent;
    background: #f4f4f5;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-treetable
    .p-treetable-tbody
    > tr
    > td
    p-treetablecheckbox
    .p-checkbox
    .p-indeterminate
    .p-checkbox-icon {
    color: #3f3f46;
  }
  .p-treetable .p-treetable-tbody > tr:focus-visible {
    outline: 0.15rem solid #d3a78a;
    outline-offset: -0.15rem;
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight {
    background: #eef2ff;
    color: #312e81;
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
    color: #312e81;
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
    color: #312e81;
  }
  .p-treetable.p-treetable-hoverable-rows
    .p-treetable-tbody
    > tr:not(.p-highlight):hover {
    background: #f4f4f5;
    color: #3f3f46;
  }
  .p-treetable.p-treetable-hoverable-rows
    .p-treetable-tbody
    > tr:not(.p-highlight):hover
    .p-treetable-toggler {
    color: #3f3f46;
  }
  .p-treetable .p-column-resizer-helper {
    background: var(--primary-color);
  }
  .p-treetable .p-treetable-scrollable-header,
  .p-treetable .p-treetable-scrollable-footer {
    background: #fafafa;
  }
  .p-treetable .p-treetable-loading-icon {
    font-size: 2rem;
  }
  .p-treetable .p-treetable-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-treetable.p-treetable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-footer {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-top {
    border-width: 0 1px 0 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-bottom {
    border-width: 0 1px 1px 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
    border-width: 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
    border-width: 1px;
  }
  .p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
    border-width: 1px;
  }
  .p-treetable.p-treetable-sm .p-treetable-header {
    padding: 1.09375rem 1.09375rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
    padding: 0.375rem 0.75rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
    padding: 0.5rem 0.75rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
    padding: 0.375rem 0.75rem;
  }
  .p-treetable.p-treetable-sm .p-treetable-footer {
    padding: 0.375rem 0.75rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-header {
    padding: 1.5625rem 1.5625rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
    padding: 0.9375rem 1.875rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
    padding: 1.25rem 1.875rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
    padding: 0.9375rem 1.875rem;
  }
  .p-treetable.p-treetable-lg .p-treetable-footer {
    padding: 0.9375rem 1.875rem;
  }

  .p-virtualscroller .p-virtualscroller-header {
    background: #fafafa;
    color: #3f3f46;
    border: 1px solid #f4f4f5;
    border-width: 1px 0 1px 0;
    padding: 1.25rem 1.25rem;
    font-weight: 600;
  }
  .p-virtualscroller .p-virtualscroller-content {
    background: #ffffff;
    color: #3f3f46;
    border: 0 none;
    padding: 0;
  }
  .p-virtualscroller .p-virtualscroller-footer {
    background: #fafafa;
    color: #3f3f46;
    border: 1px solid #f4f4f5;
    border-width: 0 0 1px 0;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1.25rem;
    border: 1px solid #e5e7eb;
    color: #3f3f46;
    background: #fafafa;
    font-weight: 700;
    border-radius: 0.375rem;
    transition: none;
  }
  .p-accordion
    .p-accordion-header
    .p-accordion-header-link
    .p-accordion-toggle-icon {
    margin-right: 0.5rem;
  }
  .p-accordion
    .p-accordion-header:not(.p-disabled)
    .p-accordion-header-link:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #d3a78a;
  }
  .p-accordion
    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
    .p-accordion-header-link {
    background: #f4f4f5;
    border-color: #e5e7eb;
    color: #3f3f46;
  }
  .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link {
    background: #fafafa;
    border-color: #e5e7eb;
    color: #3f3f46;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight:hover
    .p-accordion-header-link {
    border-color: #e5e7eb;
    background: #f4f4f5;
    color: #3f3f46;
  }
  .p-accordion .p-accordion-content {
    padding: 1.25rem;
    border: 1px solid #e5e7eb;
    background: #ffffff;
    color: #3f3f46;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .p-accordion p-accordiontab .p-accordion-tab {
    margin-bottom: 0;
  }
  .p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
    border-radius: 0;
  }
  .p-accordion p-accordiontab .p-accordion-content {
    border-radius: 0;
  }
  .p-accordion
    p-accordiontab:not(:first-child)
    .p-accordion-header
    .p-accordion-header-link {
    border-top: 0 none;
  }
  .p-accordion
    p-accordiontab:not(:first-child)
    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
    .p-accordion-header-link,
  .p-accordion
    p-accordiontab:not(:first-child)
    .p-accordion-header:not(.p-disabled).p-highlight:hover
    .p-accordion-header-link {
    border-top: 0 none;
  }
  .p-accordion
    p-accordiontab:first-child
    .p-accordion-header
    .p-accordion-header-link {
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .p-accordion
    p-accordiontab:last-child
    .p-accordion-header:not(.p-highlight)
    .p-accordion-header-link {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .p-accordion p-accordiontab:last-child .p-accordion-content {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .p-card {
    background: #ffffff;
    color: #3f3f46;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    border-radius: 1rem;
  }
  .p-card .p-card-body {
    padding: 0 1.25rem 1.25rem;
  }
  .p-card .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    padding-top: 2rem;
  }
  .p-card .p-card-subtitle {
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: #71717a;
  }
  .p-card .p-card-content {
    padding-top: 0.7rem;
    padding-bottom: 0.5rem;
  }
  .p-card .p-card-footer {
    padding: 1.25rem 0 0 0;
  }

  .p-divider .p-divider-content {
    background-color: #ffffff;
  }
  .p-divider.p-divider-horizontal {
    margin: 1rem 0;
    padding: 0 1rem;
  }
  .p-divider.p-divider-horizontal:before {
    border-top: 1px #e5e7eb;
  }
  .p-divider.p-divider-horizontal .p-divider-content {
    padding: 0 0.5rem;
  }
  .p-divider.p-divider-vertical {
    margin: 0 1rem;
    padding: 1rem 0;
  }
  .p-divider.p-divider-vertical:before {
    border-left: 1px #e5e7eb;
  }
  .p-divider.p-divider-vertical .p-divider-content {
    padding: 0.5rem 0;
  }

  .p-fieldset {
    border: 1px solid #e5e7eb;
    background: #ffffff;
    color: #3f3f46;
    border-radius: 0.375rem;
  }
  .p-fieldset .p-fieldset-legend {
    padding: 1.25rem;
    border: 1px solid #e5e7eb;
    color: #3f3f46;
    background: #fafafa;
    font-weight: 700;
    border-radius: 0.375rem;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: none;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 1.25rem;
    color: #3f3f46;
    border-radius: 0.375rem;
    transition: none;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    margin-right: 0.5rem;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background: #f4f4f5;
    border-color: #e5e7eb;
    color: #18181b;
  }
  .p-fieldset .p-fieldset-content {
    padding: 1.25rem;
  }

  .p-panel .p-panel-header {
    border: 1px solid #e5e7eb;
    padding: 1.25rem;
    background: #fafafa;
    color: #3f3f46;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .p-panel .p-panel-header .p-panel-title {
    font-weight: 700;
  }
  .p-panel .p-panel-header .p-panel-header-icon {
    width: 2rem;
    height: 2rem;
    color: #71717a;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: none;
  }
  .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    color: #18181b;
    border-color: transparent;
    background: #f4f4f5;
  }
  .p-panel .p-panel-header .p-panel-header-icon:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-panel.p-panel-toggleable .p-panel-header {
    padding: 0.75rem 1.25rem;
  }
  .p-panel .p-panel-content {
    padding: 1.25rem;
    border: 1px solid #e5e7eb;
    background: #ffffff;
    color: #3f3f46;
    border-top: 0 none;
  }
  .p-panel .p-panel-content:last-child {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .p-panel .p-panel-footer {
    padding: 0.75rem 1.25rem;
    border: 1px solid #e5e7eb;
    background: #ffffff;
    color: #3f3f46;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    border-top: 0 none;
  }
  .p-panel .p-panel-icons-end {
    order: 2;
    margin-left: auto;
  }
  .p-panel .p-panel-icons-start {
    order: 0;
    margin-right: 0.5rem;
  }
  .p-panel .p-panel-icons-center {
    order: 2;
    width: 100%;
    text-align: center;
  }

  .p-scrollpanel .p-scrollpanel-bar {
    background-color: var(--surface-300);
    border-radius: 0.375rem;
    opacity: 1;
    transition: background-color 0.2s;
    width: 0.4rem;
  }

  .p-splitter {
    border: 1px solid #e5e7eb;
    background: #ffffff;
    border-radius: 0.375rem;
    color: #3f3f46;
  }
  .p-splitter .p-splitter-gutter {
    transition: none;
    background: #fafafa;
  }
  .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: #e5e7eb;
  }
  .p-splitter .p-splitter-gutter-resizing {
    background: #e5e7eb;
  }

  .p-tabview .p-tabview-nav-content {
    scroll-padding-inline: 3rem;
  }
  .p-tabview .p-tabview-nav {
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-width: 0 0 2px 0;
  }
  .p-tabview .p-tabview-nav li {
    margin-right: 0;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid #e5e7eb;
    border-width: 0 0 2px 0;
    border-color: transparent transparent #e5e7eb transparent;
    background: #ffffff;
    color: #71717a;
    padding: 1.25rem;
    font-weight: 700;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
    transition: none;
    margin: 0 0 -2px 0;
  }
  .p-tabview
    .p-tabview-nav
    li
    .p-tabview-nav-link:not(.p-disabled):focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #d3a78a;
  }
  .p-tabview
    .p-tabview-nav
    li:not(.p-highlight):not(.p-disabled):hover
    .p-tabview-nav-link {
    background: #ffffff;
    border-color: #71717a;
    color: #71717a;
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: var(--primary-color);
    color: var(--primary-color);
  }
  .p-tabview .p-tabview-left-icon {
    margin-right: 0.5rem;
  }
  .p-tabview .p-tabview-right-icon {
    margin-left: 0.5rem;
  }
  .p-tabview .p-tabview-close {
    margin-left: 0.5rem;
  }
  .p-tabview .p-tabview-nav-btn.p-link {
    background: #ffffff;
    color: var(--primary-color);
    width: 3rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 0;
  }
  .p-tabview .p-tabview-nav-btn.p-link:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #d3a78a;
  }
  .p-tabview .p-tabview-panels {
    background: #ffffff;
    padding: 1.25rem;
    border: 0 none;
    color: #3f3f46;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .p-toolbar {
    background: #fafafa;
    border: 1px solid #e5e7eb;
    padding: 1.25rem;
    border-radius: 0.375rem;
    gap: 0.5rem;
  }
  .p-toolbar .p-toolbar-separator {
    margin: 0 0.5rem;
  }

  .p-confirm-popup {
    background: #ffffff;
    color: #3f3f46;
    border: 0 none;
    border-radius: 0.375rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .p-confirm-popup .p-confirm-popup-content {
    padding: 1.25rem;
  }
  .p-confirm-popup .p-confirm-popup-footer {
    text-align: right;
    padding: 0.75rem 1.25rem;
  }
  .p-confirm-popup .p-confirm-popup-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
  }
  .p-confirm-popup .p-confirm-popup-footer button:last-child {
    margin: 0;
  }
  .p-confirm-popup:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
  }
  .p-confirm-popup:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
  }
  .p-confirm-popup.p-confirm-popup-flipped:after {
    border-top-color: #ffffff;
  }
  .p-confirm-popup.p-confirm-popup-flipped:before {
    border-top-color: #ffffff;
  }
  .p-confirm-popup .p-confirm-popup-icon {
    font-size: 1.5rem;
  }
  .p-confirm-popup .p-confirm-popup-icon.p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-confirm-popup .p-confirm-popup-message {
    margin-left: 1rem;
  }

  .p-dialog {
    border-radius: 0.375rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    border: 0 none;
  }
  .p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #3f3f46;
    padding: 2rem;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 1.5rem;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 2rem;
    height: 2rem;
    color: #71717a;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: none;
    margin-right: 0.5rem;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: #18181b;
    border-color: transparent;
    background: #f4f4f5;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
  }
  .p-dialog .p-dialog-content {
    background: #ffffff;
    color: #3f3f46;
    padding: 0 2rem 2rem 2rem;
  }
  .p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: #ffffff;
    color: #3f3f46;
    padding: 0 2rem 2rem 2rem;
    text-align: center;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
  }
  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
  }
  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem;
  }

  .p-overlaypanel {
    background: #ffffff;
    color: #3f3f46;
    border: 0 none;
    border-radius: 0.375rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .p-overlaypanel .p-overlaypanel-content {
    padding: 1.25rem;
  }
  .p-overlaypanel .p-overlaypanel-close {
    background: var(--primary-color);
    color: #ffffff;
    width: 2rem;
    height: 2rem;
    transition: none;
    border-radius: 50%;
    position: absolute;
    top: -1rem;
    right: -1rem;
  }
  .p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: var(--primary-600);
    color: #ffffff;
  }
  .p-overlaypanel:after {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
  }
  .p-overlaypanel:before {
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #f2f2f2;
  }
  .p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: #ffffff;
  }
  .p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: #ffffff;
  }

  .p-sidebar {
    background: #ffffff;
    color: #3f3f46;
    border: 0 none;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  .p-sidebar .p-sidebar-header {
    padding: 1.25rem;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close,
  .p-sidebar .p-sidebar-header .p-sidebar-icon {
    width: 2rem;
    height: 2rem;
    color: #71717a;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: none;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
  .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: #18181b;
    border-color: transparent;
    background: #f4f4f5;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close:focus-visible,
  .p-sidebar .p-sidebar-header .p-sidebar-icon:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-sidebar .p-sidebar-header + .p-sidebar-content {
    padding-top: 0;
  }
  .p-sidebar .p-sidebar-content {
    padding: 1.25rem;
  }
  .p-sidebar .p-sidebar-footer {
    padding: 1.25rem;
  }

  .p-tooltip .p-tooltip-text {
    background: #3f3f46;
    color: #ffffff;
    padding: 0.75rem 0.75rem;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
  }
  .p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #3f3f46;
  }
  .p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #3f3f46;
  }
  .p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #3f3f46;
  }
  .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #3f3f46;
  }

  .p-fileupload .p-fileupload-buttonbar {
    background: #fafafa;
    padding: 1.25rem;
    border: 1px solid #e5e7eb;
    color: #3f3f46;
    border-bottom: 0 none;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: 0.5rem;
  }
  .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-fileupload .p-fileupload-content {
    background: #ffffff;
    padding: 2rem 1rem;
    border: 1px solid #e5e7eb;
    color: #3f3f46;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .p-fileupload .p-fileupload-content.p-fileupload-highlight {
    border-color: 1px dashed var(--primary-color);
    border-style: dashed;
    background-color: #eef2ff;
  }
  .p-fileupload .p-progressbar {
    height: 0.25rem;
  }
  .p-fileupload .p-fileupload-row > div {
    padding: 1rem 1.5rem;
  }
  .p-fileupload.p-fileupload-advanced .p-message {
    margin-top: 0;
  }

  .p-fileupload-choose:not(.p-disabled):hover {
    background: var(--primary-600);
    color: #ffffff;
    border-color: var(--primary-600);
  }
  .p-fileupload-choose:not(.p-disabled):active {
    background: var(--primary-600);
    color: #ffffff;
    border-color: var(--primary-600);
  }

  .p-breadcrumb {
    background: #ffffff;
    /* border: 1px solid #e5e7eb; */
    border-radius: 0.375rem;
    padding: 0.2rem;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link {
    transition: none;
    border-radius: 0.375rem;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
    color: #3f3f46;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-icon {
    color: #71717a;
  }
  .p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
    margin: 0 0.5rem 0 0.5rem;
    color: #3f3f46;
  }
  .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-text {
    color: #3f3f46;
  }
  .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-icon {
    color: #71717a;
  }

  .p-contextmenu {
    padding: 0.25rem 0;
    background: #ffffff;
    color: #3f3f46;
    border: 0 none;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    width: 12.5rem;
  }
  .p-contextmenu .p-contextmenu-root-list {
    outline: 0 none;
  }
  .p-contextmenu .p-submenu-list {
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content {
    color: #3f3f46;
    transition: none;
    border-radius: 0;
  }
  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #3f3f46;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-contextmenu
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #3f3f46;
  }
  .p-contextmenu
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-contextmenu
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #3f3f46;
    background: #f4f4f5;
  }
  .p-contextmenu
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #3f3f46;
  }
  .p-contextmenu
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon,
  .p-contextmenu
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-contextmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #f4f4f5;
  }
  .p-contextmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content {
    color: #18181b;
    background: #e5e7eb;
  }
  .p-contextmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #18181b;
  }
  .p-contextmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon,
  .p-contextmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #18181b;
  }
  .p-contextmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }
  .p-contextmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-text {
    color: #18181b;
  }
  .p-contextmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-icon,
  .p-contextmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-contextmenu .p-menuitem-separator {
    border-top: 1px solid #f3f4f6;
    margin: 0.25rem 0;
  }
  .p-contextmenu .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-contextmenu .p-submenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }

  .p-dock .p-dock-list-container {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
  }
  .p-dock .p-dock-list-container .p-dock-list {
    outline: 0 none;
  }
  .p-dock .p-dock-item {
    padding: 0.5rem;
    border-radius: 0.375rem;
  }
  .p-dock .p-dock-item.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #d3a78a;
  }
  .p-dock .p-dock-link {
    width: 4rem;
    height: 4rem;
  }
  .p-dock.p-dock-top .p-dock-item-second-prev,
  .p-dock.p-dock-top .p-dock-item-second-next,
  .p-dock.p-dock-bottom .p-dock-item-second-prev,
  .p-dock.p-dock-bottom .p-dock-item-second-next {
    margin: 0 0.9rem;
  }
  .p-dock.p-dock-top .p-dock-item-prev,
  .p-dock.p-dock-top .p-dock-item-next,
  .p-dock.p-dock-bottom .p-dock-item-prev,
  .p-dock.p-dock-bottom .p-dock-item-next {
    margin: 0 1.3rem;
  }
  .p-dock.p-dock-top .p-dock-item-current,
  .p-dock.p-dock-bottom .p-dock-item-current {
    margin: 0 1.5rem;
  }
  .p-dock.p-dock-left .p-dock-item-second-prev,
  .p-dock.p-dock-left .p-dock-item-second-next,
  .p-dock.p-dock-right .p-dock-item-second-prev,
  .p-dock.p-dock-right .p-dock-item-second-next {
    margin: 0.9rem 0;
  }
  .p-dock.p-dock-left .p-dock-item-prev,
  .p-dock.p-dock-left .p-dock-item-next,
  .p-dock.p-dock-right .p-dock-item-prev,
  .p-dock.p-dock-right .p-dock-item-next {
    margin: 1.3rem 0;
  }
  .p-dock.p-dock-left .p-dock-item-current,
  .p-dock.p-dock-right .p-dock-item-current {
    margin: 1.5rem 0;
  }

  @media screen and (max-width: 960px) {
    .p-dock.p-dock-top .p-dock-list-container,
    .p-dock.p-dock-bottom .p-dock-list-container {
      overflow-x: auto;
      width: 100%;
    }
    .p-dock.p-dock-top .p-dock-list-container .p-dock-list,
    .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
      margin: 0 auto;
    }
    .p-dock.p-dock-left .p-dock-list-container,
    .p-dock.p-dock-right .p-dock-list-container {
      overflow-y: auto;
      height: 100%;
    }
    .p-dock.p-dock-left .p-dock-list-container .p-dock-list,
    .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
      margin: auto 0;
    }
    .p-dock .p-dock-list .p-dock-item {
      transform: none;
      margin: 0;
    }
  }
  .p-megamenu {
    padding: 1rem;
    background: #fafafa;
    color: #3f3f46;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
  }
  .p-megamenu .p-megamenu-root-list {
    outline: 0 none;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content {
    color: #3f3f46;
    transition: none;
    border-radius: 0;
  }
  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #3f3f46;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-megamenu
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #3f3f46;
  }
  .p-megamenu
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-megamenu
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #3f3f46;
    background: #f4f4f5;
  }
  .p-megamenu
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #3f3f46;
  }
  .p-megamenu
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon,
  .p-megamenu
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-megamenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #f4f4f5;
  }
  .p-megamenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content {
    color: #18181b;
    background: #e5e7eb;
  }
  .p-megamenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #18181b;
  }
  .p-megamenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon,
  .p-megamenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #18181b;
  }
  .p-megamenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }
  .p-megamenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-text {
    color: #18181b;
  }
  .p-megamenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-icon,
  .p-megamenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-megamenu .p-megamenu-panel {
    background: #ffffff;
    color: #3f3f46;
    border: 0 none;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .p-megamenu .p-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #3f3f46;
    background: #ffffff;
    font-weight: 600;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .p-megamenu .p-submenu-list {
    padding: 0.25rem 0;
    width: 12.5rem;
  }
  .p-megamenu .p-submenu-list .p-menuitem-separator {
    border-top: 1px solid #f3f4f6;
    margin: 0.25rem 0;
  }
  .p-megamenu.p-megamenu-vertical {
    width: 12.5rem;
    padding: 0.25rem 0;
  }
  .p-megamenu.p-megamenu-horizontal
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-content {
    color: #3f3f46;
    transition: none;
    border-radius: 0.375rem;
  }
  .p-megamenu.p-megamenu-horizontal
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link {
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-megamenu.p-megamenu-horizontal
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #3f3f46;
  }
  .p-megamenu.p-megamenu-horizontal
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-megamenu.p-megamenu-horizontal
    .p-megamenu-root-list
    > .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
    margin-left: 0.5rem;
  }
  .p-megamenu.p-megamenu-horizontal
    .p-megamenu-root-list
    > .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }
  .p-megamenu.p-megamenu-horizontal
    .p-megamenu-root-list
    > .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-text {
    color: #18181b;
  }
  .p-megamenu.p-megamenu-horizontal
    .p-megamenu-root-list
    > .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-icon,
  .p-megamenu.p-megamenu-horizontal
    .p-megamenu-root-list
    > .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }

  .p-menu {
    padding: 0.25rem 0;
    background: #ffffff;
    color: #3f3f46;
    border: 1px solid #e5e7eb;
    border-radius: 0.6rem;
    width: 12.5rem;
    color: #3f3f46;
  }
  .p-menu .p-menuitem > .p-menuitem-content {
    /* color: #3f3f46; */
    transition: none;
    border-radius: 0;
  }
  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    /* color: #3f3f46; */
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    /* color: #3f3f46; */
  }
  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    /* color: #71717a; */
    margin-right: 0.5rem;
  }
  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #71717a;
  }
  .p-menu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #3f3f46;
    background: #f4f4f5;
  }
  .p-menu
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #3f3f46;
  }
  .p-menu
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon,
  .p-menu
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-menu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #f4f4f5;
  }
  .p-menu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content {
    color: #18181b;
    background: #e5e7eb;
  }
  .p-menu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #18181b;
  }
  .p-menu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon,
  .p-menu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #18181b;
  }
  .p-menu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }
  .p-menu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-text {
    color: #18181b;
  }
  .p-menu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-icon,
  .p-menu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-menu.p-menu-overlay {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .p-menu .p-submenu-header {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #3f3f46;
    background: #ffffff;
    font-weight: 600;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .p-menu .p-menuitem-separator {
    border-top: 1px solid #f3f4f6;
    margin: 0.25rem 0;
  }
  .p-menu .p-menuitem-badge {
    background: var(--primary-color);
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 0.375rem;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .p-menubar {
    padding: 1rem;
    background: #fafafa;
    color: #3f3f46;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
  }
  .p-menubar .p-menubar-root-list {
    outline: 0 none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content {
    color: #3f3f46;
    transition: none;
    border-radius: 0.375rem;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link {
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #3f3f46;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
    margin-left: 0.5rem;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-text {
    color: #18181b;
  }
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-icon,
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-menubar .p-menuitem > .p-menuitem-content {
    color: #3f3f46;
    transition: none;
    border-radius: 0;
  }
  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #3f3f46;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-menubar
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #3f3f46;
  }
  .p-menubar
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-menubar
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content {
    color: #3f3f46;
    background: #f4f4f5;
  }
  .p-menubar
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #3f3f46;
  }
  .p-menubar
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon,
  .p-menubar
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-menubar .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #f4f4f5;
  }
  .p-menubar
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content {
    color: #18181b;
    background: #e5e7eb;
  }
  .p-menubar
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #18181b;
  }
  .p-menubar
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon,
  .p-menubar
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #18181b;
  }
  .p-menubar
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }
  .p-menubar
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-text {
    color: #18181b;
  }
  .p-menubar
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-icon,
  .p-menubar
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-menubar .p-submenu-list {
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    width: 12.5rem;
  }
  .p-menubar .p-submenu-list .p-menuitem-separator {
    border-top: 1px solid #f3f4f6;
    margin: 0.25rem 0;
  }
  .p-menubar .p-submenu-list .p-submenu-icon {
    font-size: 0.875rem;
  }

  @media screen and (max-width: 960px) {
    .p-menubar {
      position: relative;
    }
    .p-menubar .p-menubar-button {
      display: flex;
      width: 2rem;
      height: 2rem;
      color: #71717a;
      border-radius: 50%;
      transition: none;
    }
    .p-menubar .p-menubar-button:hover {
      color: #71717a;
      background: #f4f4f5;
    }
    .p-menubar .p-menubar-button:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 1px #d3a78a;
    }
    .p-menubar .p-menubar-root-list {
      position: absolute;
      display: none;
      padding: 0.25rem 0;
      background: #ffffff;
      border: 0 none;
      box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      width: 100%;
    }
    .p-menubar .p-menubar-root-list .p-menuitem-separator {
      border-top: 1px solid #f3f4f6;
      margin: 0.25rem 0;
    }
    .p-menubar .p-menubar-root-list .p-submenu-icon {
      font-size: 0.875rem;
    }
    .p-menubar .p-menubar-root-list .p-menuitem {
      width: 100%;
      position: static;
    }
    .p-menubar
      .p-menubar-root-list
      .p-menuitem
      .p-menuitem-content
      .p-menuitem-link
      .p-submenu-icon {
      margin-left: auto;
      transition: transform 0.2s;
    }
    .p-menubar
      .p-menubar-root-list
      .p-menuitem.p-menuitem-active
      > .p-menuitem-content
      > .p-menuitem-link
      > .p-submenu-icon {
      transform: rotate(-180deg);
    }
    .p-menubar .p-menubar-root-list .p-submenu-list {
      width: 100%;
      position: static;
      box-shadow: none;
      border: 0 none;
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
      transition: transform 0.2s;
      transform: rotate(90deg);
    }
    .p-menubar
      .p-menubar-root-list
      .p-submenu-list
      .p-menuitem-active
      > .p-menuitem-content
      > .p-menuitem-link
      > .p-submenu-icon {
      transform: rotate(-90deg);
    }
    .p-menubar .p-menubar-root-list .p-menuitem {
      width: 100%;
      position: static;
    }
    .p-menubar
      .p-menubar-root-list
      .p-submenu-list
      .p-menuitem
      .p-menuitem-content
      .p-menuitem-link {
      padding-left: 2.25rem;
    }
    .p-menubar
      .p-menubar-root-list
      .p-submenu-list
      .p-menuitem
      .p-submenu-list
      .p-menuitem
      .p-menuitem-content
      .p-menuitem-link {
      padding-left: 3.75rem;
    }
    .p-menubar
      .p-menubar-root-list
      .p-submenu-list
      .p-menuitem
      .p-submenu-list
      .p-menuitem
      .p-submenu-list
      .p-menuitem
      .p-menuitem-content
      .p-menuitem-link {
      padding-left: 5.25rem;
    }
    .p-menubar
      .p-menubar-root-list
      .p-submenu-list
      .p-menuitem
      .p-submenu-list
      .p-menuitem
      .p-submenu-list
      .p-menuitem
      .p-submenu-list
      .p-menuitem
      .p-menuitem-content
      .p-menuitem-link {
      padding-left: 6.75rem;
    }
    .p-menubar
      .p-menubar-root-list
      .p-submenu-list
      .p-menuitem
      .p-submenu-list
      .p-menuitem
      .p-submenu-list
      .p-menuitem
      .p-submenu-list
      .p-menuitem
      .p-submenu-list
      .p-menuitem
      .p-menuitem-content
      .p-menuitem-link {
      padding-left: 8.25rem;
    }
    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
      display: flex;
      flex-direction: column;
      top: 100%;
      left: 0;
      z-index: 1;
    }
  }
  .p-panelmenu .p-panelmenu-header {
    outline: 0 none;
  }
  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
    border: 1px solid #e5e7eb;
    color: #3f3f46;
    background: #fafafa;
    border-radius: 0.375rem;
    transition: none;
  }
  .p-panelmenu
    .p-panelmenu-header
    .p-panelmenu-header-content
    .p-panelmenu-header-action {
    color: #3f3f46;
    padding: 1.25rem;
    font-weight: 700;
  }
  .p-panelmenu
    .p-panelmenu-header
    .p-panelmenu-header-content
    .p-panelmenu-header-action
    .p-submenu-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu
    .p-panelmenu-header
    .p-panelmenu-header-content
    .p-panelmenu-header-action
    .p-menuitem-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu
    .p-panelmenu-header:not(.p-disabled):focus-visible
    .p-panelmenu-header-content {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #d3a78a;
  }
  .p-panelmenu
    .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover
    .p-panelmenu-header-content {
    background: #f4f4f5;
    border-color: #e5e7eb;
    color: #3f3f46;
  }
  .p-panelmenu
    .p-panelmenu-header:not(.p-disabled).p-highlight
    .p-panelmenu-header-content {
    background: #fafafa;
    border-color: #e5e7eb;
    color: #3f3f46;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
  }
  .p-panelmenu
    .p-panelmenu-header:not(.p-disabled).p-highlight:hover
    .p-panelmenu-header-content {
    border-color: #e5e7eb;
    background: #f4f4f5;
    color: #3f3f46;
  }
  .p-panelmenu .p-panelmenu-content {
    padding: 0.25rem 0;
    border: 1px solid #e5e7eb;
    background: #ffffff;
    color: #3f3f46;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .p-panelmenu .p-panelmenu-content .p-panelmenu-root-list {
    outline: 0 none;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content {
    color: #3f3f46;
    transition: none;
    border-radius: 0;
  }
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link {
    color: #3f3f46;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #3f3f46;
  }
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem.p-highlight
    > .p-menuitem-content {
    color: #3f3f46;
    background: #f4f4f5;
  }
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #3f3f46;
  }
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon,
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem.p-highlight.p-focus
    > .p-menuitem-content {
    background: #f4f4f5;
  }
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content {
    color: #18181b;
    background: #e5e7eb;
  }
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #18181b;
  }
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon,
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #18181b;
  }
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-text {
    color: #18181b;
  }
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-icon,
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-panelmenu
    .p-panelmenu-content
    .p-menuitem
    .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem-separator {
    border-top: 1px solid #f3f4f6;
    margin: 0.25rem 0;
  }
  .p-panelmenu
    .p-panelmenu-content
    .p-submenu-list:not(.p-panelmenu-root-list) {
    padding: 0 0 0 1rem;
  }
  .p-panelmenu .p-panelmenu-panel {
    margin-bottom: 0;
  }
  .p-panelmenu
    .p-panelmenu-panel
    .p-panelmenu-header
    .p-panelmenu-header-content {
    border-radius: 0;
  }
  .p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
    border-radius: 0;
  }
  .p-panelmenu
    .p-panelmenu-panel:not(:first-child)
    .p-panelmenu-header
    .p-panelmenu-header-content {
    border-top: 0 none;
  }
  .p-panelmenu
    .p-panelmenu-panel:not(:first-child)
    .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover
    .p-panelmenu-header-content,
  .p-panelmenu
    .p-panelmenu-panel:not(:first-child)
    .p-panelmenu-header:not(.p-disabled).p-highlight:hover
    .p-panelmenu-header-content {
    border-top: 0 none;
  }
  .p-panelmenu
    .p-panelmenu-panel:first-child
    .p-panelmenu-header
    .p-panelmenu-header-content {
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
  }
  .p-panelmenu
    .p-panelmenu-panel:last-child
    .p-panelmenu-header:not(.p-highlight)
    .p-panelmenu-header-content {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }

  .p-slidemenu {
    padding: 0.25rem 0;
    background: #ffffff;
    color: #3f3f46;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    width: 12.5rem;
  }
  .p-slidemenu .p-slidemenu-root-list {
    outline: 0 none;
  }
  .p-slidemenu .p-submenu-list {
    outline: 0 none;
  }
  .p-slidemenu .p-menuitem > .p-menuitem-content {
    color: #3f3f46;
    transition: none;
    border-radius: 0;
  }
  .p-slidemenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #3f3f46;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-slidemenu
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #3f3f46;
  }
  .p-slidemenu
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-slidemenu
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-slidemenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #3f3f46;
    background: #f4f4f5;
  }
  .p-slidemenu
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #3f3f46;
  }
  .p-slidemenu
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon,
  .p-slidemenu
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-slidemenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #f4f4f5;
  }
  .p-slidemenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content {
    color: #18181b;
    background: #e5e7eb;
  }
  .p-slidemenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #18181b;
  }
  .p-slidemenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon,
  .p-slidemenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #18181b;
  }
  .p-slidemenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }
  .p-slidemenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-text {
    color: #18181b;
  }
  .p-slidemenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-icon,
  .p-slidemenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-slidemenu.p-slidemenu-overlay {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .p-slidemenu .p-slidemenu-list {
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .p-slidemenu .p-menuitem-separator {
    border-top: 1px solid #f3f4f6;
    margin: 0.25rem 0;
  }
  .p-slidemenu .p-slidemenu-icon {
    font-size: 0.875rem;
  }
  .p-slidemenu .p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }
  .p-slidemenu .p-slidemenu-backward {
    padding: 0.75rem 1rem;
    color: #3f3f46;
  }
  .p-slidemenu .p-slidemenu-backward:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #d3a78a;
  }
  .p-slidemenu .p-menuitem-badge {
    background: var(--primary-color);
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 0.375rem;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .p-steps .p-steps-item .p-menuitem-link {
    background: transparent;
    transition: none;
    border-radius: 0.375rem;
    background: #ffffff;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: #3f3f46;
    border: 1px solid #f4f4f5;
    background: #ffffff;
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 50%;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: 0.5rem;
    color: #71717a;
  }
  .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #eef2ff;
    color: #312e81;
  }
  .p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 600;
    color: #3f3f46;
  }
  .p-steps .p-steps-item:before {
    content: ' ';
    border-top: 1px solid #f3f4f6;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem;
  }

  .p-tabmenu .p-tabmenu-nav {
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-width: 0 0 2px 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-menuitem-badge {
    background: var(--primary-color);
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 0.375rem;
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border: solid #e5e7eb;
    border-width: 0 0 2px 0;
    border-color: transparent transparent #e5e7eb transparent;
    background: #ffffff;
    color: #71717a;
    padding: 1.25rem;
    font-weight: 700;
    border-top-right-radius: 0.375rem;
    border-top-left-radius: 0.375rem;
    transition: none;
    margin: 0 0 -2px 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: 0.5rem;
  }
  .p-tabmenu
    .p-tabmenu-nav
    .p-tabmenuitem
    .p-menuitem-link:not(.p-disabled):focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #d3a78a;
  }
  .p-tabmenu
    .p-tabmenu-nav
    .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover
    .p-menuitem-link {
    background: #ffffff;
    border-color: #71717a;
    color: #71717a;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: #ffffff;
    border-color: var(--primary-color);
    color: var(--primary-color);
  }
  .p-tabmenu .p-tabmenu-left-icon {
    margin-right: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-right-icon {
    margin-left: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-nav-btn.p-link {
    background: #ffffff;
    color: var(--primary-color);
    width: 3rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 0;
  }
  .p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 1px #d3a78a;
  }

  .p-tieredmenu {
    padding: 0.25rem 0;
    background: #ffffff;
    color: #3f3f46;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    width: 12.5rem;
  }
  .p-tieredmenu.p-tieredmenu-overlay {
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .p-tieredmenu .p-tieredmenu-root-list {
    outline: 0 none;
  }
  .p-tieredmenu .p-submenu-list {
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content {
    color: #3f3f46;
    transition: none;
    border-radius: 0;
  }
  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #3f3f46;
    padding: 0.75rem 1rem;
    user-select: none;
  }
  .p-tieredmenu
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #3f3f46;
  }
  .p-tieredmenu
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon {
    color: #71717a;
    margin-right: 0.5rem;
  }
  .p-tieredmenu
    .p-menuitem
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #3f3f46;
    background: #f4f4f5;
  }
  .p-tieredmenu
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #3f3f46;
  }
  .p-tieredmenu
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon,
  .p-tieredmenu
    .p-menuitem.p-highlight
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-tieredmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #f4f4f5;
  }
  .p-tieredmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content {
    color: #18181b;
    background: #e5e7eb;
  }
  .p-tieredmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-text {
    color: #18181b;
  }
  .p-tieredmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-menuitem-icon,
  .p-tieredmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus
    > .p-menuitem-content
    .p-menuitem-link
    .p-submenu-icon {
    color: #18181b;
  }
  .p-tieredmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover {
    color: #18181b;
    background: #f4f4f5;
  }
  .p-tieredmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-text {
    color: #18181b;
  }
  .p-tieredmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-menuitem-icon,
  .p-tieredmenu
    .p-menuitem:not(.p-highlight):not(.p-disabled)
    > .p-menuitem-content:hover
    .p-menuitem-link
    .p-submenu-icon {
    color: #71717a;
  }
  .p-tieredmenu .p-menuitem-separator {
    border-top: 1px solid #f3f4f6;
    margin: 0.25rem 0;
  }
  .p-tieredmenu .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-tieredmenu .p-submenu-icon.p-icon {
    width: 0.875rem;
    height: 0.875rem;
  }

  .p-inline-message {
    padding: 0.75rem 0.75rem;
    margin: 0;
    border-radius: 0.375rem;
  }
  .p-inline-message.p-inline-message-info {
    background: #eff6ff;
    border: none;
    border-width: 0px;
    color: #2563eb;
  }
  .p-inline-message.p-inline-message-info .p-inline-message-icon {
    color: #2563eb;
  }
  .p-inline-message.p-inline-message-success {
    background: #ecfdf5;
    border: none;
    border-width: 0px;
    color: #059669;
  }
  .p-inline-message.p-inline-message-success .p-inline-message-icon {
    color: #059669;
  }
  .p-inline-message.p-inline-message-warn {
    background: #fef3c7;
    border: none;
    border-width: 0px;
    color: #d97706;
  }
  .p-inline-message.p-inline-message-warn .p-inline-message-icon {
    color: #d97706;
  }
  .p-inline-message.p-inline-message-error {
    background: #fef3c7;
    border: none;
    border-width: 0px;
    color: #dc2626;
  }
  .p-inline-message.p-inline-message-error .p-inline-message-icon {
    color: #dc2626;
  }
  .p-inline-message .p-inline-message-icon {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
  .p-inline-message .p-icon {
    width: 1rem;
    height: 1rem;
  }
  .p-inline-message .p-inline-message-text {
    font-size: 1rem;
  }
  .p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0;
  }

  .p-message {
    margin: 1rem 0;
    border-radius: 0.375rem;
  }
  .p-message .p-message-wrapper {
    padding: 1.25rem 1.75rem;
  }
  .p-message .p-message-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: none;
  }
  .p-message .p-message-close:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  .p-message .p-message-close:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-message.p-message-info {
    background: #eff6ff;
    border: none;
    border-width: 0 0 0 6px;
    color: #2563eb;
  }
  .p-message.p-message-info .p-message-icon {
    color: #2563eb;
  }
  .p-message.p-message-info .p-message-close {
    color: #2563eb;
  }
  .p-message.p-message-success {
    background: #ecfdf5;
    border: none;
    border-width: 0 0 0 6px;
    color: #059669;
  }
  .p-message.p-message-success .p-message-icon {
    color: #059669;
  }
  .p-message.p-message-success .p-message-close {
    color: #059669;
  }
  .p-message.p-message-warn {
    background: #fef3c7;
    border: none;
    border-width: 0 0 0 6px;
    color: #d97706;
  }
  .p-message.p-message-warn .p-message-icon {
    color: #d97706;
  }
  .p-message.p-message-warn .p-message-close {
    color: #d97706;
  }
  .p-message.p-message-error {
    background: #fce4e4;
    border-left: solid #dc2626;
    border-width: 0 0 0 6px;
    color: #dc2626;
  }
  .p-message.p-message-error .p-message-icon {
    color: #dc2626;
  }
  .p-message.p-message-error .p-message-close {
    color: #dc2626;
  }
  .p-message .p-message-text {
    font-size: 1rem;
    font-weight: 500;
  }
  .p-message .p-message-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  .p-message .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .p-message .p-message-summary {
    font-weight: 700;
  }
  .p-message .p-message-detail {
    margin-left: 0.5rem;
  }

  .p-toast {
    opacity: 0.9;
  }
  .p-toast .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-radius: 0.375rem;
  }
  .p-toast .p-toast-message .p-toast-message-content {
    padding: 1rem;
    border-width: 0 0 0 6px;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 0 0 1rem;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 2rem;
  }
  .p-toast
    .p-toast-message
    .p-toast-message-content
    .p-icon:not(.p-toast-icon-close-icon) {
    width: 2rem;
    height: 2rem;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-weight: 700;
  }
  .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: 0.5rem 0 0 0;
  }
  .p-toast .p-toast-message .p-toast-icon-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    transition: none;
  }
  .p-toast .p-toast-message .p-toast-icon-close:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  .p-toast .p-toast-message .p-toast-icon-close:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-toast .p-toast-message.p-toast-message-info {
    background: #eff6ff;
    border: solid #2563eb;
    border-width: 0 0 0 6px;
    color: #2563eb;
  }
  .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
    color: #2563eb;
  }
  .p-toast .p-toast-message.p-toast-message-success {
    background: #ecfdf5;
    border: solid #059669;
    border-width: 0 0 0 6px;
    color: #059669;
  }
  .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    color: #059669;
  }
  .p-toast .p-toast-message.p-toast-message-warn {
    background: #fef3c7;
    border: solid #d97706;
    border-width: 0 0 0 6px;
    color: #d97706;
  }
  .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
    color: #d97706;
  }
  .p-toast .p-toast-message.p-toast-message-error {
    background: #fce4e4;
    border: solid #dc2626;
    border-width: 0 0 0 6px;
    color: #dc2626;
  }
  .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
  .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: #dc2626;
  }

  .p-galleria .p-galleria-close {
    margin: 0.5rem;
    background: transparent;
    color: #fafafa;
    width: 4rem;
    height: 4rem;
    transition: none;
    border-radius: 50%;
  }
  .p-galleria .p-galleria-close .p-galleria-close-icon {
    font-size: 2rem;
  }
  .p-galleria .p-galleria-close .p-icon-wrapper .p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-galleria .p-galleria-close:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #fafafa;
  }
  .p-galleria .p-galleria-item-nav {
    background: transparent;
    color: #fafafa;
    width: 4rem;
    height: 4rem;
    transition: none;
    border-radius: 0.375rem;
    margin: 0 0.5rem;
  }
  .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
  .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 2rem;
  }
  .p-galleria .p-galleria-item-nav .p-icon-wrapper .p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
    background: rgba(255, 255, 255, 0.1);
    color: #fafafa;
  }
  .p-galleria .p-galleria-caption {
    background: rgba(0, 0, 0, 0.5);
    color: #fafafa;
    padding: 1rem;
  }
  .p-galleria .p-galleria-indicators {
    padding: 1rem;
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: #f4f4f5;
    width: 1rem;
    height: 1rem;
    transition: none;
    border-radius: 50%;
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
    background: #e5e7eb;
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: #eef2ff;
    color: #312e81;
  }
  .p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
  .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
    margin-right: 0.5rem;
  }
  .p-galleria.p-galleria-indicators-left .p-galleria-indicator,
  .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
    margin-bottom: 0.5rem;
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: rgba(0, 0, 0, 0.5);
  }
  .p-galleria.p-galleria-indicator-onitem
    .p-galleria-indicators
    .p-galleria-indicator
    button {
    background: rgba(255, 255, 255, 0.4);
  }
  .p-galleria.p-galleria-indicator-onitem
    .p-galleria-indicators
    .p-galleria-indicator
    button:hover {
    background: rgba(255, 255, 255, 0.6);
  }
  .p-galleria.p-galleria-indicator-onitem
    .p-galleria-indicators
    .p-galleria-indicator.p-highlight
    button {
    background: #eef2ff;
    color: #312e81;
  }
  .p-galleria .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, 0.9);
    padding: 1rem 0.25rem;
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    margin: 0.5rem;
    background-color: transparent;
    color: #fafafa;
    width: 2rem;
    height: 2rem;
    transition: none;
    border-radius: 50%;
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #fafafa;
  }
  .p-galleria
    .p-galleria-thumbnail-container
    .p-galleria-thumbnail-item-content:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }

  .p-galleria-mask {
    --maskbg: rgba(0, 0, 0, 0.9);
  }

  .p-image-mask {
    --maskbg: rgba(0, 0, 0, 0.9);
  }

  .p-image-preview-indicator {
    background-color: transparent;
    color: #f8f9fa;
    transition: none;
  }

  .p-image-preview-container:hover > .p-image-preview-indicator {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .p-image-toolbar {
    padding: 1rem;
  }

  .p-image-action.p-link {
    color: #f8f9fa;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    transition: none;
    margin-right: 0.5rem;
  }
  .p-image-action.p-link:last-child {
    margin-right: 0;
  }
  .p-image-action.p-link:hover {
    color: #f8f9fa;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .p-image-action.p-link i {
    font-size: 1.5rem;
  }
  .p-image-action.p-link .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .p-avatar {
    background-color: #e5e7eb;
    border-radius: 0.375rem;
  }
  .p-avatar.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
  }
  .p-avatar.p-avatar-lg .p-avatar-icon {
    font-size: 1.5rem;
  }
  .p-avatar.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
  }
  .p-avatar.p-avatar-xl .p-avatar-icon {
    font-size: 2rem;
  }

  .p-avatar-group .p-avatar {
    border: 2px solid #ffffff;
  }

  .p-badge {
    background: var(--primary-color);
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }
  .p-badge.p-badge-secondary {
    background-color: #64748b;
    color: #ffffff;
  }
  .p-badge.p-badge-success {
    background-color: #22c55e;
    color: #ffffff;
  }
  .p-badge.p-badge-info {
    background-color: #3b82f6;
    color: #ffffff;
  }
  .p-badge.p-badge-warning {
    background-color: #f59e0b;
    color: #ffffff;
  }
  .p-badge.p-badge-danger {
    background-color: #ef4444;
    color: #ffffff;
  }
  .p-badge.p-badge-lg {
    font-size: 1.125rem;
    min-width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
  }
  .p-badge.p-badge-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem;
  }

  .p-chip {
    background-color: #e5e7eb;
    color: #3f3f46;
    border-radius: 16px;
    padding: 0 0.75rem;
  }
  .p-chip .p-chip-text {
    line-height: 1.5;
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .p-chip .p-chip-icon {
    margin-right: 0.5rem;
  }
  .p-chip .pi-chip-remove-icon {
    margin-left: 0.5rem;
  }
  .p-chip img {
    width: 2.25rem;
    height: 2.25rem;
    margin-left: -0.75rem;
    margin-right: 0.5rem;
  }
  .p-chip .pi-chip-remove-icon {
    border-radius: 0.375rem;
    transition: none;
  }
  .p-chip .pi-chip-remove-icon:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }
  .p-chip .pi-chip-remove-icon:focus {
    outline: 0 none;
  }

  .p-inplace .p-inplace-display {
    padding: 0.75rem 0.75rem;
    border-radius: 0.375rem;
    transition: none;
  }
  .p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: #f4f4f5;
    color: #18181b;
  }
  .p-inplace .p-inplace-display:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px #d3a78a;
  }

  .p-progressbar {
    border: 0 none;
    height: 1.5rem;
    background: #e5e7eb;
    border-radius: 0.375rem;
  }
  .p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: var(--primary-color);
  }
  .p-progressbar .p-progressbar-label {
    color: #ffffff;
    line-height: 1.5rem;
  }

  .p-scrolltop {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition: none;
  }
  .p-scrolltop.p-link {
    background: rgba(0, 0, 0, 0.7);
  }
  .p-scrolltop.p-link:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  .p-scrolltop .p-scrolltop-icon {
    font-size: 1.5rem;
    color: #fafafa;
  }
  .p-scrolltop .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .p-skeleton {
    background-color: #f4f4f5;
    border-radius: 0.375rem;
  }
  .p-skeleton:after {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0)
    );
  }

  .p-tag {
    background: var(--primary-color);
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.05rem 0.65rem;
    border-radius: 0.4rem;
  }
  .p-tag.p-tag-success {
    background-color: #22c55e;
    color: #ffffff;
  }
  .p-tag.p-tag-info {
    background-color: #3b82f6;
    color: #ffffff;
  }
  .p-tag.p-tag-warning {
    background-color: #f59e0b;
    color: #ffffff;
  }
  .p-tag.p-tag-danger {
    background-color: #ef4444;
    color: #ffffff;
  }
  .p-tag .p-tag-icon {
    margin-right: 0.25rem;
    font-size: 0.75rem;
  }
  .p-tag .p-icon {
    width: 0.75rem;
    height: 0.75rem;
  }

  .p-terminal {
    background: #ffffff;
    color: #3f3f46;
    border: 1px solid #e5e7eb;
    padding: 1.25rem;
  }
  .p-terminal .p-terminal-input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
  }
}

/* Customizations to the designer theme should be defined here */
@layer primeng {
  .p-inputtext,
  .p-togglebutton,
  .p-selectbutton,
  .p-inputgroup {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .p-inputgroup .p-inputtext,
  .p-inputgroup .p-togglebutton,
  .p-inputgroup .p-selectbutton {
    box-shadow: none;
  }

  .p-inputtext.p-invalid.p-component:enabled:focus {
    box-shadow: 0 0 0 1px #f0a9a7;
  }

  .p-highlight {
    font-weight: 600;
  }

  .p-button-label {
    font-weight: 500;
  }

  .p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: 0 0 0 2px #d3a78a;
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    border-color: var(--primary-color);
    margin-right: 1px;
  }
  .p-paginator .p-paginator-current {
    border: 0 none;
  }

  .p-button:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #d3a78a,
      0 1px 2px 0 rgba(0, 0, 0, 0);
  }
  .p-button.p-button-secondary:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #eceef2,
      0 1px 2px 0 rgba(0, 0, 0, 0);
  }
  .p-button.p-button-success:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #22c55e,
      0 1px 2px 0 rgba(0, 0, 0, 0);
  }
  .p-button.p-button-info:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #3b82f6,
      0 1px 2px 0 rgba(0, 0, 0, 0);
  }
  .p-button.p-button-warning:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #f59e0b,
      0 1px 2px 0 rgba(0, 0, 0, 0);
  }
  .p-button.p-button-help:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #a855f7,
      0 1px 2px 0 rgba(0, 0, 0, 0);
  }
  .p-button.p-button-danger:enabled:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #ef4444,
      0 1px 2px 0 rgba(0, 0, 0, 0);
  }

  .p-checkbox .p-checkbox-box {
    border-radius: 0.25rem;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #d3a78a,
      0 1px 2px 0 rgba(0, 0, 0, 0);
  }

  .p-radiobutton:not(.p-radiobutton-disabled) .p-radiobutton-box.p-focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #d3a78a,
      0 1px 2px 0 rgba(0, 0, 0, 0);
  }

  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 var(--primary-color);
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 var(--primary-color);
  }

  .field > label {
    font-weight: 500;
  }
  input::placeholder,
  textarea::placeholder,
  input[type='text']::placeholder,
  input[type='password']::placeholder,
  input[type='email']::placeholder,
  input[type='number']::placeholder {
    color: var(--gray-200);
  }

  .p-inputtext.ng-dirty.ng-invalid,
  .p-autocomplete.ng-dirty.ng-invalid,
  .p-dropdown.ng-dirty.ng-invalid,
  .p-inputnumber.ng-dirty.ng-invalid,
  .p-inputtextarea.ng-dirty.ng-invalid,
  .p-multiselect.ng-dirty.ng-invalid,
  .p-slider.ng-dirty.ng-invalid,
  .p-textarea.ng-dirty.ng-invalid,
  .p-textbox.ng-dirty.ng-invalid {
    border-color: #f0a9a7;
    box-shadow: 0px 0px 0px 4px #fee2e2;
  }

  .layout-menuitem-text {
    font-weight: 600;
  }

  .p-fluid .p-button {
    justify-content: center;
  }

  .p-breadcrumb
    .p-breadcrumb-list
    li.link-breadcrumb
    .p-menuitem-link
    .p-menuitem-text:hover {
    color: var(--primary-color);
    text-decoration: underline;
  }
}

.p-menu .p-menuitem > .p-menuitem-content {
  color: currentColor;
}
.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
  color: currentColor;
}
.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
  color: currentColor;
}
.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
  color: currentColor;
}

/* Sidebar right filters */
.p-sidebar-right {
  width: 300px;
  border-radius: 16px 0 0 16px;
}
.p-sidebar-content {
  background: var(--gray-50);
  padding: 24px 20px;
}
.p-fileupload .p-message-detail {
  overflow-wrap: anywhere;
}
